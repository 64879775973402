import React from "react";
import useObserveCodicentSettings from "../useObserveCodicentSettings";
import { Dropdown, Panel, Stack } from "office-ui-fabric-react";
import { ProjectInfo } from "../useObserveProject";
import { ParagraphTitle } from "./Controls";
import { useTranslation } from "react-i18next";
import { Button } from "./Button";
import { InfoMessageBar } from "./InfoMessageBar";

// const theme = useTheme();

const ProfileSettingsPanel = ({
  isOpen,
  onDismiss,
  project,
  username,
}: {
  isOpen: boolean;
  onDismiss: () => void;
  project: ProjectInfo;
  username: string;
}) => {
  const settings = useObserveCodicentSettings({ username, project });
  const { audioDevices, selectedDeviceId, setSelectedDeviceId } = settings;
  const { t } = useTranslation();

  const dismiss = () => {
    onDismiss();
  };

  return (
    <Panel isOpen={isOpen} isLightDismiss onDismiss={dismiss}>
      <Stack tokens={{ childrenGap: 20 }}>
        <ParagraphTitle>{t("Settings")}</ParagraphTitle>
        {settings.isUser && (
          <Stack tokens={{ childrenGap: 10 }}>
            {audioDevices && audioDevices.length === 0 && (
              <div style={{ minHeight: "2.5vh" }}>
                <InfoMessageBar text={t("No audio devices found")} />
              </div>
            )}
            {audioDevices.length > 0 && (
              <Dropdown
                label={t("Select audio device")}
                defaultSelectedKey={
                  selectedDeviceId || (audioDevices.length > 0 ? audioDevices[0].deviceId : undefined)
                }
                onChange={(_e, value) => setSelectedDeviceId(value ? (value.key as string) : undefined)}
                options={audioDevices.map((d) => {
                  return { key: d.deviceId, text: d.label };
                })}
              />
            )}
          </Stack>
        )}

        <Stack.Item>
          <Button primary text={t("Close")} onClick={dismiss} />
        </Stack.Item>
      </Stack>
    </Panel>
  );
};

export default ProfileSettingsPanel;
