// In production, we register a service worker to serve assets from local cache.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on the "N+1" visit to a page, since previously
// cached resources are updated in the background.

// To learn more about the benefits of this model, read https://goo.gl/KwvDNy.
// This link also includes instructions on opting out of this behavior.

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === '[::1]' ||
  // 127.0.0.1/8 is considered localhost for IPv4.
  window.location.hostname.match(
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
  )
);

export default function register() {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location);
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebookincubator/create-react-app/issues/2374
      return;
    }

    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

      if (isLocalhost) {
        // This is running on localhost. Lets check if a service worker still exists or not.
        checkValidServiceWorker(swUrl);
      } else {
        // Is not local host. Just register service worker
        registerValidSW(swUrl);
      }
    });
  }
}

function registerValidSW(swUrl) {
  navigator.serviceWorker
    .register(swUrl)
    .then(registration => {
      registration.pushManager.getSubscription().then(sub => {
        if (sub === null) {
          // Update UI to ask user to register for Push
          console.log('Not subscribed to push service!');
        } else {
          // We have a subscription, update the database
          console.log('Subscription object: ', sub);
        }
      });

      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              // At this point, the old content will have been purged and
              // the fresh content will have been added to the cache.
              // It's the perfect time to display a "New content is
              // available; please refresh." message in your web app.
              console.log('New content is available; please refresh.');
              window.location.reload();
            } else {
              // At this point, everything has been precached.
              // It's the perfect time to display a
              // "Content is cached for offline use." message.
              console.log('Content is cached for offline use.');
            }
          }
        };
      };
    })
    .catch(error => {
      console.error('Error during service worker registration:', error);
    });
}

function checkValidServiceWorker(swUrl) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl)
    .then(response => {
      // Ensure service worker exists, and that we really are getting a JS file.
      if (
        response.status === 404 ||
        response.headers.get('content-type').indexOf('javascript') === -1
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then(registration => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl);
      }
    })
    .catch(() => {
      console.log(
        'No internet connection found. App is running in offline mode.'
      );
    });
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister();
    });
  }
}

/*setInterval(() => {
  const token = localStorage.getItem('token');
  if (token) {
    const parseJwt = (token) => {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      return JSON.parse(jsonPayload);
    };
    const { nickname } = parseJwt(token);
    fetch(`/app/GetChatMessages?start=0&length=1`,
      {
        method: "GET",
        headers: [
          ["Content-Type", "application/json; charset=utf-8"],
          ["Authorization", `Bearer ${token}`],
        ],
      }
    ).then((response) => {
      if (response.ok) {
        response.json()
          .then(serverMessages => {
            if (serverMessages.length === 1) {
              const newMessage = serverMessages[0];
              const { id } = newMessage;
              const hiddenMessages = JSON.parse(localStorage.getItem("hiddenMessages") || "[]");
              const messages = JSON.parse(localStorage.getItem("messages") || "[]");
              const workerLastMessage = localStorage.getItem("workerLastMessage");
              const hasMessage = (messages.length > 0 && messages[0].id === id) || (hiddenMessages.length > 0 && hiddenMessages[0] === id) || (workerLastMessage === id);
              if (!hasMessage) {
                localStorage.setItem("workerLastMessage", id);
                if (newMessage.nickname !== nickname) {
                  if (typeof Notification !== "undefined") {
                    if (Notification.permission === "granted") {
                      let title = "Codicent";
                      let icon = '/logo512.png';
                      let body = newMessage.content;
                      var notification = new Notification(title, { body, icon });
                      notification.onclick = () => {
                        notification.close();
                        window.parent.focus();
                      }
                    }
                  }
                }
              }
            }
          })
          .catch(() => { });
      }
    })
      .catch(err => console.log(err));
  }
}, 60 * 1000);*/


// // Initialize the Firebase app in the service worker by passing in
// // your app's Firebase config object.
// // https://firebase.google.com/docs/web/setup#config-object
// const firebaseApp = initializeApp({
//   apiKey: "AIzaSyBBrQL-Xq8pnAb3Ogvr-25F-gGou7dq1uM",
//   authDomain: "codicent.firebaseapp.com",
//   projectId: "codicent",
//   storageBucket: "codicent.appspot.com",
//   messagingSenderId: "769503731135",
//   appId: "1:769503731135:web:b4b55871bf9e830217150a",
// });

// // // Retrieve an instance of Firebase Messaging so that it can handle background
// // // messages.
// // const messaging = getMessaging(firebaseApp);

// // // const messaging = getMessaging();
// // onBackgroundMessage(messaging, (payload) => {
// //   console.log('[firebase-messaging-sw.js] Received background message ', payload);
// //   // Customize notification here
// //   const notificationTitle = 'Background Message Title';
// //   const notificationOptions = {
// //     body: 'Background Message body.',
// //     icon: '/logo512.png'
// //   };

// //   self.registration.showNotification(notificationTitle,
// //     notificationOptions);
// // });

// const messaging = getMessaging();

// onBackgroundMessage(messaging, (payload) => {
//   console.log('[firebase-messaging-sw.js] Received background message ', payload);
//   // Customize notification here
//   const notificationTitle = 'Background Message Title';
//   const notificationOptions = {
//     body: 'Background Message body.',
//     icon: '/logo512.png'
//   };

//   self.registration.showNotification(notificationTitle,
//     notificationOptions);
// });