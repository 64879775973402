import React from "react";
import { Label } from "office-ui-fabric-react";
import { useTranslation } from "react-i18next";
import { CodicentGrid } from "./CodicentGrid";

const CodicentsPage = () => {
   const { t } = useTranslation();
   return <>
      <Label>{t("Store Codicents")}</Label>
      <CodicentGrid />
   </>;
};

export default CodicentsPage;
