import React from "react";
import { MessageBar, MessageBarType } from "office-ui-fabric-react";

export const InfoMessageBar = ({ text, actions }: { text?: string; actions?: JSX.Element }) => {
  if (text) {
    return (
      <MessageBar messageBarType={MessageBarType.info} actions={actions}>
        {text}
      </MessageBar>
    );
  } else {
    return <></>;
  }
};
