import { useState, useEffect, useRef } from "react";
import { FileHelpers } from "./file";
import { ChatMessage } from "./models";
import Api from "./api";
import { useTranslation } from "react-i18next";

type AudioRecorderState = "uninitialized" | "initialized" | "saving" | "message_sent" | "recording";

export interface ObserveAudioRecorderProps {
  initializeAudioRecorder: () => void;
  uninitializeAudioRecorder: () => void;
  canRecord: boolean;
  error?: string;
  audioRecorderState: AudioRecorderState;
  timeRecorded: number;
  setTranscriptionProject: (project: string) => void;
  transcriptionProject?: string;
  setTranscriptionTag: (tag: string) => void;
  transcriptionTag?: string;
  startRecording: () => void;
  stopRecording: () => void;
}

export const useObserveAudioRecorder = (): ObserveAudioRecorderProps => {
  const [getAudio, setAudio] = useState<Blob>();
  const [recorder, setRecorder] = useState<MediaRecorder>();
  const [selectedDeviceId, setSelectedDeviceId] = useState<string>();
  const [error, setError] = useState<string>();
  const [audioRecorderState, setAudioRecorderState] = useState<AudioRecorderState>("uninitialized");
  const { t } = useTranslation();
  const chunksRef = useRef<BlobPart[]>([]);
  const [transcriptionProject, setTranscriptionProject] = useState<string>();
  const [transcriptionTag, setTranscriptionTag] = useState<string>();
  const [timeRecorded, setTimeRecorded] = useState(0);
  const intervalRef = useRef<NodeJS.Timeout>();
  const timeRecordedRef = useRef<number>(0);

  const getSupportedMimeType = () => {
    const mimeTypes = ["audio/webm;codecs=opus", "audio/ogg;codecs=opus", "audio/mp4"];
    for (let type of mimeTypes) {
      if (MediaRecorder.isTypeSupported(type)) {
        return type;
      }
    }
    return "";
  };

  const initializeAudioRecorder = () => {
    if (recorder) {
      uninitializeAudioRecorder();
    }

    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      setError(t("Webbläsaren stöder inte inspelning av ljud"));
      return;
    }

    if (typeof MediaRecorder === "undefined") {
      setError(t("MediaRecorder stöds inte av denna webbläsare"));
      return;
    }

    const deviceId = localStorage.getItem("audio_device_id") || undefined;
    if (deviceId) {
      setSelectedDeviceId(deviceId);
      createRecorder()
        .then((recorder) => {
          setAudioRecorderState("initialized");
          setRecorder(recorder);
        })
        .catch(() => setError(t("Error creating audio recorder")));
    } else {
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          const audioDevices = devices.filter((d) => d.kind === "audioinput");
          if (audioDevices.length > 0) {
            setSelectedDeviceId(audioDevices[0].deviceId);
            localStorage.setItem("audio_device_id", audioDevices[0].deviceId);
            createRecorder()
              .then((recorder) => {
                setAudioRecorderState("initialized");
                setRecorder(recorder);
              })
              .catch(() => setError(t("Error creating audio recorder")));
          } else {
            setError(t("No audio devices found"));
          }
        })
        .catch(() => setError(t("Error enumerating devices")));
    }
  };

  const uninitializeAudioRecorder = () => {
    stopRecording();
    setRecorder(undefined);
  };

  const startRecording = () => {
    if (recorder && recorder.state !== "recording") {
      setAudioRecorderState("recording");
      setTimeRecorded(0);
      timeRecordedRef.current = 0;
      recorder.start();
      intervalRef.current = setInterval(() => {
        timeRecordedRef.current++;
        setTimeRecorded(timeRecordedRef.current);
      }, 1000);
    }
  };

  const stopRecording = () => {
    if (recorder && recorder.state === "recording") {
      setAudioRecorderState("initialized");
      recorder.stop();
      clearInterval(intervalRef.current!);
    }
  };

  const sendMessage = async (text: string) => {
    const item: ChatMessage = {
      id: undefined,
      parentId: undefined,
      content: text,
      type: "text",
      createdAt: new Date(),
      nickname: "johan",
      isNew: false,
    };

    try {
      await Api.addChatMessage(item);
    } catch (error: any) {
      setError(error.message);
    }
  };

  useEffect(() => {
    if (getAudio) {
      setAudioRecorderState("saving");
      const filename = "audio.webm"; // Anpassa efter MIME-typen
      const formData = new FormData();
      formData.append("file", getAudio, filename);
      FileHelpers.uploadFile(filename, formData)
        .then((fileGuid) => {
          sendMessage(
            `@${transcriptionProject}${
              transcriptionTag ? " #" + transcriptionTag.trim().replace("#", "") : ""
            } #transcribe #file:${fileGuid}`
          )
            .then(() => {
              setAudio(undefined);
              chunksRef.current = []; // setChunks([]); // Om du fortfarande använder setChunks någonstans
              setAudioRecorderState("message_sent");
              setTimeout(() => {
                setAudioRecorderState("initialized");
              }, 1000);
            })
            .catch((err) => {
              setError(err.message);
              setAudioRecorderState("initialized");
            });
        })
        .catch((error) => {
          setError(error.message);
          setAudioRecorderState("initialized");
        });
    }
  }, [getAudio, transcriptionProject, transcriptionTag, t]);

  const createRecorder = () =>
    new Promise<MediaRecorder>((resolve, reject) => {
      navigator.mediaDevices
        .getUserMedia({ audio: { deviceId: selectedDeviceId ? { exact: selectedDeviceId } : undefined } })
        .then((stream) => {
          const mimeType = getSupportedMimeType();
          if (!mimeType) {
            reject(new Error("Ingen kompatibel MIME-typ hittades"));
            return;
          }
          let mediaRecorder = new MediaRecorder(stream, { mimeType });

          mediaRecorder.ondataavailable = (event) => {
            chunksRef.current.push(event.data);
          };

          mediaRecorder.onstop = () => {
            const audioBlob = new Blob(chunksRef.current, { type: mimeType });
            setAudio(audioBlob);
            chunksRef.current = [];
          };

          resolve(mediaRecorder);
        })
        .catch(reject);
    });

  return {
    initializeAudioRecorder,
    uninitializeAudioRecorder,
    canRecord: !!recorder,
    error,
    audioRecorderState,
    timeRecorded,
    setTranscriptionProject,
    transcriptionProject,
    setTranscriptionTag,
    transcriptionTag,
    startRecording,
    stopRecording,
  };
};
