import React, { Suspense, lazy, useEffect, useState } from "react";
import * as signalR from "@microsoft/signalr";
import { Route, useParams } from "react-router";
import "./custom.css";
import { initializeIcons } from "@uifabric/icons";
import { Redirect, RouteComponentProps, Switch } from "react-router-dom";
import { useObserveUser } from "./useObserveUser";
import { initializeApp } from "firebase/app";
import "firebase/messaging";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { firebaseConfig, firebaseVapidKey } from "./firebase";
import Api from "./api";
import { version } from "../package.json";
import { ChatMessage } from "./models";
import CodicentsPage from "./components/CodicentsPage";
import { I18nextProvider, useTranslation } from "react-i18next";
import i18n from "./i18n";
import { useObserveAudioRecorder } from "./useObserveAudioRecorder";
import { hostMappings } from "./hostMappings";
import { getSubdomain } from "./helpers";
import { useAuth0 } from "@auth0/auth0-react";
import { RegisterAuth0User } from "./components/RegisterAuth0User";
import Profile from "./components/Profile";
import queryString from "query-string";

const User = lazy(() => import("./components/User"));
const Search = lazy(() => import("./components/Search"));
const ProjectPage = lazy(() => import("./components/Project"));
const ShowImage = lazy(() => import("./components/ShowImage"));
const Statistics = lazy(() => import("./components/Statistics"));
const Transcriber = lazy(() => import("./components/Transcriber"));
const FormPage = lazy(() => import("./components/FormPage"));
const Home = lazy(() => import("./components/Home"));
const Compose = lazy(() => import("./components/Compose"));
const New = lazy(() => import("./components/New"));
const ChatPage = lazy(() => import("./components/ChatPage"));
const PrintChatPage = lazy(() => import("./components/PrintChatPage"));
const FilesPage = lazy(() => import("./components/FilesPage"));
const TodoPage = lazy(() => import("./components/TodoPage"));
const DataPage = lazy(() => import("./components/DataPage"));
const HistoryPage = lazy(() => import("./components/HistoryPage"));
const ProjectCompose = lazy(() => import("./components/ProjectCompose"));
const ChatPage2 = lazy(() => import("./components/ChatPage2"));
const Dashboard = lazy(() => import("./components/Dashboard"));
const Builder = lazy(() => import("./components/Builder"));
const Beta = lazy(() => import("./components/Beta"));
const AcceptInvitation = lazy(() => import("./components/AcceptInvitation"));
const Planner = lazy(() => import("./components/Planner"));
const Board = lazy(() => import("./components/Board"));
const CameraAndAudioPage = lazy(() => import("./components/CameraAndAudioPage"));

const Loading = () => <div />;

initializeIcons();

const app = initializeApp(firebaseConfig);

const initializeFirebaseMessaging = () => {
  // Initialize Firebase Cloud Messaging and get a reference to the service
  const messaging = getMessaging(app);

  // Add the public key generated from the console here: https://console.firebase.google.com/project/codicent/settings/cloudmessaging
  getToken(messaging, {
    vapidKey: firebaseVapidKey,
  })
    .then((currentToken) => {
      if (currentToken) {
        // console.log("Messaging token: ", currentToken);
        Api.registerDevice(currentToken)
          .then(() => console.log("Device registered"))
          .catch(console.warn);

        onMessage(messaging, (payload) => {
          // console.log("Message received. ", payload);
          const notification = payload.notification;
          if (notification) {
            const msg = new Notification(notification.title || "Codicent", {
              body: notification.body,
              icon: notification.image,
            });
            msg.addEventListener("click", function (event) {
              if (payload.data && payload.data.url) {
                event.preventDefault(); // Prevents the browser's default behavior
                // Navigate to the specific URL
                window.open(payload.data.url, "_blank");
              }
            });
          }
        });
      } else {
        // Show permission request UI
        console.log("No registration token available. Request permission to generate one.");
        // ...
      }
    })
    .catch((err: any) => {
      console.log("An error occurred while retrieving token. ", err);
      // ...
    });
};

export const App = () => {
  const user = useObserveUser(true);
  const audioRecorder = useObserveAudioRecorder();
  const { authService, getVariable } = user;
  const [hubUrl, setHubUrl] = useState<string>();
  const { isAuthenticated, logout, user: _user, getAccessTokenSilently } = useAuth0();
  const [showRegister, setShowRegister] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      // console.log("auth0 is authenticated");
      if (!authService.isAuthenticated()) {
        // console.log("authService is NOT authenticated");
        // console.log(_user);
        getAccessTokenSilently({ audience: "https://codicent.com/api" })
          .then((accessToken) => {
            Api.loginAuth0User(_user.sub, accessToken)
              .then((token) => {
                // console.log("auth0 user registered", token);
                localStorage.setItem("token", token);
              })
              .catch(() => {
                setShowRegister(true);
              });
          })
          .catch(console.warn);
      }
    }
  }, [isAuthenticated]);

  const canAnalyzeChats = getVariable("CODICENT_ANALYZE_CHATS") !== null;

  useEffect(() => {
    if (!hubUrl) {
      Api.getInstanceInfo()
        .then((info) => {
          if (info.hub) {
            setHubUrl(info.hub);
          } else {
            console.warn("No hub url found");
          }
        })
        .catch(console.warn);
    }
  }, []);

  // Setup Firebase messaging and SignalR => wait for incoming messages
  useEffect(() => {
    if (user.isAuthenticated && user.nickname && hubUrl) {
      initializeFirebaseMessaging();

      let retryTimeout: NodeJS.Timeout | undefined;

      const connection = new signalR.HubConnectionBuilder()
        .withUrl(hubUrl, {
          accessTokenFactory: () => user.token!,
        })
        .withAutomaticReconnect()
        .build();

      connection.on("NewMessage", (message: ChatMessage) => {
        //   console.log(message);
        // if (message.nickname !== user.nickname) {
        user.checkForNewMessages();
        // }
        window.dispatchEvent(
          new CustomEvent("newmessage", {
            detail: message,
          })
        );
      });

      connection.onclose(() => {
        // console.log("Connection closed");
        setTimeout(startConnection, 5000);
      });

      connection.onreconnected((_connectionId) => {
        // console.log("Connection reconnected");
        user.checkForNewMessages();
        window.dispatchEvent(new CustomEvent("reconnect"));
      });

      const startConnection = () => {
        // Guard against multiple starts
        if (connection.state !== signalR.HubConnectionState.Disconnected) {
          return;
        }
        connection
          .start()
          .then(() => {
            user.checkForNewMessages();
          })
          .catch((err) => {
            // console.log("Connection start failed", err);
            retryTimeout = setTimeout(startConnection, 5000);
          });
      };

      startConnection();

      return () => {
        connection.stop().catch(() => {});
        if (retryTimeout) clearTimeout(retryTimeout);
      };
    }
  }, [user.isAuthenticated, user.nickname, hubUrl]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderUser = () => {
    let resultComponent = (
      <Suspense fallback={<Loading />}>
        <User user={user} />
      </Suspense>
    );

    // if (!isAuthenticated) {
    //   resultComponent = <Redirect to="/" />;
    // }

    return resultComponent;
  };

  const renderProfile = () => {
    let resultComponent = (
      <Suspense fallback={<Loading />}>
        <Profile user={user} />
      </Suspense>
    );

    return resultComponent;
  };

  const startSession = (history: any) => {
    authService.handleAuthentication(history);
    return (
      <div>
        <p>Starting session...</p>
      </div>
    );
  };

  const renderRedirect = () => {
    window.location.replace("https://codicent.se");
    return null;
  };

  // const Web = ({ visible }: { visible: boolean }) => {
  //   const { url } = queryString.parse(window.location.search);
  //   useEffect(() => {
  //     if (visible) {
  //       setTimeout(() => window.location.replace(url as string), 0);
  //     }
  //   }, [visible, url]);
  //   // setTimeout(() => window.location.replace(q as string), 0);
  //   return (
  //     <div
  //       style={{
  //         display: visible ? "block" : "none",
  //         zIndex: 1000,
  //         position: "absolute",
  //         width: "100%",
  //         height: "100%",
  //       }}
  //     >
  //       Redirecting...
  //     </div>
  //   );
  // };

  const renderNew = (props: RouteComponentProps<any>) => {
    const originalHost = window.location.hostname;
    const subdomain = getSubdomain();
    const log = hostMappings[originalHost] || subdomain || undefined;
    return (
      <Suspense fallback={<Loading />}>
        {log && <New subdomain={log} user={user} />}
        <Compose log={log} user={user} visible={user.isAuthenticated} goBackOnClose={true} />
      </Suspense>
    );
  };

  const renderHome2 = (props: RouteComponentProps<any>) => {
    const originalHost = window.location.hostname;
    const subdomain = getSubdomain();
    const log = hostMappings[originalHost] || subdomain || undefined;
    var { q } = queryString.parse(window.location.search);

    if (!!subdomain && (!q || q === "") && user.isAuthenticated && !props.location.pathname.startsWith("/compose")) {
      const appUrl = localStorage.getItem("CODICENT_APP_URL");
      if (appUrl && appUrl.trim().length > 1) {
        Api.getUserInfo(subdomain).then((pi) => {
          const appUrl = pi.properties.find((v) => v.name === "CODICENT_APP_URL");
          if (appUrl && appUrl.value && appUrl.value.trim().length > 1) {
            window.location.href = appUrl.value;
            localStorage.setItem("CODICENT_APP_URL", appUrl.value);
          } else {
            localStorage.removeItem("CODICENT_APP_URL");
          }
        });

        window.location.href = appUrl;
        return <></>;
      }
    }

    return (
      <>
        <Suspense fallback={<Loading />}>
          <Compose
            log={log}
            user={user}
            visible={props.location.pathname.startsWith("/compose") && user.isAuthenticated}
            goBackOnClose={props.location.pathname.startsWith("/compose/") && document.referrer !== ""}
          />
        </Suspense>
        <Suspense fallback={<Loading />}>
          <ShowImage visible={props.location.pathname.startsWith("/image")} />
        </Suspense>
        <Suspense fallback={<Loading />}>
          <Home
            log={log}
            user={user}
            {...props}
            hasFocus={props.location.pathname === "/" || props.location.pathname === "/log/" + log}
            audioRecorder={audioRecorder}
          />
        </Suspense>
        {/* <Web visible={props.location.pathname.startsWith("/web/")} /> */}
      </>
    );
  };

  // const renderWeb = () => {
  //   //const { q } = useParams<{ q: string }>();
  //   const { q } = queryString.parse(window.location.search);
  //   let link: any;
  //   // setTimeout(() => link.click(), 1000);
  //   setTimeout(() => window.location.replace(q as string), 0);
  //   return <>Redirecting...</>;
  // };

  const { ready } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [ready]);

  if (showRegister) {
    return (
      <RegisterAuth0User
        onRegister={(token: string) => {
          localStorage.setItem("token", token);
          setShowRegister(false);
        }}
      />
    );
  }

  return (
    <I18nextProvider i18n={i18n}>
      <div className="App">
        {/* <header className="App-header">
          <h1 className="App-title">Codicent</h1>
        </header> */}
        <Switch>
          {/* <Route exact path="/web" render={renderHome2} /> */}
          {/* <Route exact path="/web" render={renderHome2} /> */}
          <Route exact path="/" render={renderHome2} />
          <Route exact path="/version" render={() => <pre style={{ marginLeft: 8 }}>{version}</pre>} />

          <Route path="/startSession" render={({ history }) => startSession(history)} />
          <Route exact path="/user" render={() => renderUser()} />
          <Route exact path="/profile" render={() => renderProfile()} />
          <Route
            exact
            path="/search"
            render={() => (
              <Suspense fallback={<Loading />}>
                <Search />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/project"
            render={() => (
              <Suspense fallback={<Loading />}>
                <ProjectPage />
              </Suspense>
            )}
          />
          <Route exact path="/compose/:log" render={renderHome2} />
          <Route exact path="/compose" render={renderHome2} />
          <Route exact path="/new" render={renderNew} />
          <Route exact path="/image" render={renderHome2} />
          {/* <Route exact path="/compose3" render={renderCompose3} /> */}
          {/* <Route exact path="/home2" render={renderHome2} /> */}
          {/* <Route exact path="/file/:id" render={() => <ShowImage />} /> */}
          <Route exact path="/about" render={renderRedirect} />
          <Route
            exact
            path="/builder/:project"
            render={() => (
              <Suspense fallback={<Loading />}>
                <Builder />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/beta/:project"
            render={() => (
              <Suspense fallback={<Loading />}>
                <Beta user={user} />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/beta/:project/:id"
            render={() => (
              <Suspense fallback={<Loading />}>
                <Beta user={user} />
              </Suspense>
            )}
          />
          <Route exact path="/log/:log" render={renderHome2} />
          <Route
            exact
            path="/stats"
            render={() => (
              <Suspense fallback={<Loading />}>
                <Statistics />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/codicents"
            render={() => (
              <Suspense fallback={<Loading />}>
                <CodicentsPage />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/transcribe/:project"
            render={() => (
              <Suspense fallback={<Loading />}>
                <Transcriber user={user} logger={false} audioRecorder={audioRecorder} />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/form/:project/:page"
            render={() => (
              <Suspense fallback={<Loading />}>
                <FormPage />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/chat/:project/:id"
            render={() => (
              <Suspense fallback={<Loading />}>
                <ChatPage canAnalyzeChats={canAnalyzeChats} />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/printchat/:project/:id"
            render={() => (
              <Suspense fallback={<Loading />}>
                <PrintChatPage />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/room/:project/:id"
            render={() => (
              <Suspense fallback={<Loading />}>
                <ChatPage2 />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/history/:id"
            render={() => (
              <Suspense fallback={<Loading />}>
                <HistoryPage />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/chatcompose"
            render={() => (
              <Suspense fallback={<Loading />}>
                <Compose user={user} visible={true} goBackOnClose={true} />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/act/:project"
            render={() => (
              <Suspense fallback={<Loading />}>
                <ProjectCompose user={user} />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/do/:project"
            render={() => (
              <Suspense fallback={<Loading />}>
                <ProjectCompose user={user} />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/chat"
            render={() => {
              const project = localStorage.getItem("CODICENT_DEFAULT_PROJECT");
              if (project) {
                return <Redirect to={`/chat/${project}`} />;
              } else {
                return (
                  <Suspense fallback={<Loading />}>
                    <ChatPage canAnalyzeChats={canAnalyzeChats} />
                  </Suspense>
                );
              }
            }}
          />
          <Route
            exact
            path="/chat/:project"
            render={() => (
              <Suspense fallback={<Loading />}>
                <ChatPage canAnalyzeChats={canAnalyzeChats} />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/room/:project"
            render={() => (
              <Suspense fallback={<Loading />}>
                <ChatPage2 />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/files/:project"
            render={() => (
              <Suspense fallback={<Loading />}>
                <FilesPage user={user} />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/todo/:project"
            render={() => (
              <Suspense fallback={<Loading />}>
                <TodoPage user={user} />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/data/:project/:tag"
            render={() => (
              <Suspense fallback={<Loading />}>
                <DataPage user={user} />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/dashboard/:project/:tag"
            render={() => (
              <Suspense fallback={<Loading />}>
                <Dashboard />
              </Suspense>
            )}
          />
          <Route
            path="/acceptinvitation"
            render={() => (
              <Suspense fallback={<Loading />}>
                <AcceptInvitation />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/plan/:codicent/:state"
            render={() => (
              <Suspense fallback={<Loading />}>
                <Planner user={user} />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/plan/:codicent"
            render={() => (
              <Suspense fallback={<Loading />}>
                <Planner user={user} />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/board/:codicent"
            render={() => (
              <Suspense fallback={<Loading />}>
                <Board user={user} />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/record/:codicent"
            render={() => (
              <Suspense fallback={<Loading />}>
                <CameraAndAudioPage user={user} audioRecorder={audioRecorder} />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/logout"
            render={() => {
              authService.logout();
              if (isAuthenticated) {
                logout();
              }
              return <Redirect to="/" />;
            }}
          />
          <Route exact path="/:user" render={() => renderUser()} />
          {/* <Route path="/image/:id" component={ShowImage} /> */}
          {/* TODO: use renderImage? */}
        </Switch>
      </div>
    </I18nextProvider>
  );
};
