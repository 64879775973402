import React, { useEffect } from "react";
import { TextField } from "office-ui-fabric-react";
import { useState } from "react";
import Api from "../api";

export const NicknameTextField = (props: { name: string; onChange: (name: string) => void }) => {
  const [state, setState] = useState({ nicknameOk: false, nameErrorMessage: "" });
  const { name, onChange } = props;

  useEffect(() => {
    const validate = async () => {
      if (name.length < 3) {
        setState({ nicknameOk: false, nameErrorMessage: "Nickname to short!" });
      } else if (!name.match(/^[a-zA-Z0-9-_]+$/g)) {
        setState({
          nicknameOk: false,
          nameErrorMessage:
            "Nickname contains invalid characters! Allowed characters for a nickname are: letters, numbers, underscores, and hyphens.",
        });
      } else {
        const exists = await Api.nicknameExists(name);
        if (exists) {
          setState({ nicknameOk: false, nameErrorMessage: "Nickname already taken!" });
        } else {
          setState({ nicknameOk: true, nameErrorMessage: "" });
          return "";
        }
      }
    };
    validate();
  }, [name]);

  return (
    <TextField
      label="Name"
      value={name}
      id="name"
      styles={{ fieldGroup: { width: 250 } }}
      onChange={(_e, newValue) => {
        if (!newValue || newValue.length <= 32) {
          onChange(newValue || "");
        }
      }}
      required={name.length < 5}
      errorMessage={state.nameErrorMessage}
      autoComplete="on"
    />
  );
};
