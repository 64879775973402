import React, { useEffect, useState } from "react";
import { Stack, TextField, Label, Image, ImageFit, mergeStyleSets, PrimaryButton } from "office-ui-fabric-react";
import { useHistory, useParams } from "react-router-dom";
import { useTheme } from "../useTheme";
import { UploadFile } from "./UploadFile";
import Api from "../api";
import { useTranslation } from "react-i18next";
import { ObserveUserProps } from "../useObserveUser";
import ProfileSettingsPanel from "./ProfileSettingsPanel";
import Header from "./Header";
import { getSubdomain } from "../helpers";
import { useObserveAppTheme } from "../useObserveAppTheme";
import { useMsal } from "@azure/msal-react";

const theme = useTheme();
const styles = mergeStyleSets({
  image: {
    width: "120px",
    height: "120px",
    backgroundColor: theme.semanticColors.bodyStandoutBackground,
  },
});

interface UserProps {
  user: ObserveUserProps;
}

const Profile = (props: UserProps) => {
  const { user } = props;
  const { project, setCurrentProject } = user;
  const params = useParams<{ user: string }>();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [fileId, setFileId] = useState("");
  const [picture, setPicture] = useState("");
  const [isSettingsPanelOpen, setIsSettingsPanelOpen] = useState(false);
  const { t } = useTranslation();
  const { appTheme /*, buttonSt*/ } = useObserveAppTheme(getSubdomain() || "default");
  const { color, backgroundcolor, title: codicentTitle } = appTheme;
  const { instance } = useMsal();
  const history = useHistory();

  useEffect(() => {
    setCurrentProject(params.user);
  }, [params.user, setCurrentProject]);

  useEffect(() => {
    document.title = `${codicentTitle || t("Profile")}`;
  }, [codicentTitle]);

  useEffect(() => {
    setDescription(project.userInfo.description);
    setPicture(project.userInfo.picture);
    setName(project.userInfo.name);
    setEmail(project.userInfo.email);
  }, [project]);

  useEffect(() => {
    if (fileId !== "") {
      setPicture(fileId);
    }
  }, [fileId]);

  const handleUpdate = (field: string, value: string) => {
    if (field === "description") {
      setDescription(value);
      project.updateProject(value, picture, name, email);
    } else if (field === "name") {
      setName(value);
      project.updateProject(description, picture, value, email);
    } else if (field === "email") {
      setEmail(value);
      project.updateProject(description, picture, name, value);
    }
  };

  const openSettingsPanel = () => {
    setIsSettingsPanelOpen(true);
  };

  const closeSettingsPanel = () => {
    setIsSettingsPanelOpen(false);
  };

  return (
    <Stack>
      <Header
        color={backgroundcolor || theme.palette.themePrimary}
        textColor={color || theme.palette.white}
        title={codicentTitle || params.user || t("Profile")}
        menuItems={[
          {
            key: "settings",
            text: t("Settings"),
            onClick: openSettingsPanel,
          },
          {
            key: "logout",
            text: t("Logout"),
            onClick: () => {
              user.authService.logout();
              instance.logoutPopup({
                postLogoutRedirectUri: "/",
                mainWindowRedirectUri: "/",
              });
            },
          },
        ]}
        id={"profile-header"}
        // logo="https://codicent.com/app/DownloadFile?fileId=6d017cdb-b0ba-417c-8968-08dcdc9a7a2f"
        onBackClick={() => history.goBack()}
      />
      <Stack style={{ margin: 4, padding: 4 }} tokens={{ childrenGap: 14 }}>
        {/* Description */}
        <Stack.Item>
          {t("Description")}
          <TextField
            value={description}
            multiline
            rows={3}
            placeholder={t("Enter project description/slogan")}
            onChange={(_event, newValue?: string) => handleUpdate("description", newValue || "")}
          />
        </Stack.Item>

        {/* Name */}
        <Stack.Item>
          <Label>{t("Name")}</Label>
          <TextField
            value={name}
            placeholder={t("Enter name")}
            onChange={(_event, newValue?: string) => handleUpdate("name", newValue || "")}
          />
        </Stack.Item>

        {/* Email */}
        <Stack.Item>
          <Label>{t("Email")}</Label>
          <TextField
            value={email}
            placeholder={t("Enter email")}
            onChange={(_event, newValue?: string) => handleUpdate("email", newValue || "")}
          />
        </Stack.Item>

        {/* Profile Image */}
        <Stack.Item>
          <Label>{t("Profile image")}</Label>
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <Image
              src={Api.imageUrl(picture, 120)}
              alt={"image"}
              imageFit={ImageFit.contain}
              className={styles.image}
            />
            <UploadFile onFileUploaded={(fileIds) => setFileId(fileIds[0])} onUploading={() => {}} />
          </Stack>
        </Stack.Item>

        {/* Settings Button */}
        {/* <Stack.Item>
          <DefaultButton text={t("Settings")} onClick={openSettingsPanel} />
        </Stack.Item> */}
        {/* <Stack.Item>
          <PrimaryButton
            styles={buttonStyles}
            onClick={() => {
              user.authService.logout();
              instance.logoutPopup({
                postLogoutRedirectUri: "/",
                mainWindowRedirectUri: "/",
              });
            }}
          >
            {t("Logout")}
          </PrimaryButton>
        </Stack.Item> */}
      </Stack>

      <ProfileSettingsPanel
        isOpen={isSettingsPanelOpen}
        onDismiss={closeSettingsPanel}
        project={project}
        username={user.nickname}
      />
    </Stack>
  );
};

export default Profile;
