import { useCallback, useEffect, useState, useRef } from "react";
import Api from "./api";
import { Property, UserInfo } from "./models";
import { useHistory } from "react-router-dom";

export interface ProjectInfo {
  followers: string[];
  projects: string[];
  mentions: string[];
  owner?: string;
  properties: Property[];
  addOrUpdateProperty: (name: string, value: string, isSecret?: boolean) => Promise<void>;
  deleteProperty: (name: string) => void;
  nickname: string;
  userInfo: UserInfo;
  addFollower: (nickname: string) => void;
  removeFollower: (nickname: string) => void;
  picture: string;
  description: string;
  name: string;
  email: string;
  updateProject: (description: string, picture: string, name: string, email: string) => Promise<void>;
  getPropertyValue: (name: string) => string | null;
  visibleProjects: string[];
  setHiddenProjects: (hiddenProjects: string[]) => Promise<void>;
  addProject: (name: string) => Promise<void>;
  cloneProject: (name: string, newName: string) => Promise<void>;
  deleteProject: (name: string) => Promise<void>;
  refresh: () => void;
  coAdmins: string[];
}

export const useObserveProject = (nickname: string): ProjectInfo => {
  const [followers, setFollowers] = useState<string[]>([]);
  const [projects, setProjects] = useState<string[]>([]);
  const [mentions, setMentions] = useState<string[]>([]);
  const [owner, setOwner] = useState<string>();
  const [properties, setProperties] = useState<Property[]>([]);
  const [userInfo, setUserInfo] = useState<UserInfo>({} as UserInfo);
  const [description, setDescription] = useState<string>("");
  const [picture, setPicture] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [visibleProjects, setVisibleProjects] = useState<string[]>([]);
  const isMountedRef = useRef(true);
  const [coAdmins, setCoAdmins] = useState<string[]>([]);
  const history = useHistory();

  const sort = (properties: Property[]) =>
    properties.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

  const update = useCallback(
    (force = false) => {
      Api.getUserInfo(nickname, force)
        .then((userInfo) => {
          if (!isMountedRef.current) return;
          const followers = userInfo.followers.map((f) => f.followingUser);
          const projects = userInfo.projects.map((o) => o.nickname);
          const mentions = followers.concat(projects).sort();
          setFollowers(followers);
          setProjects(projects.sort((a, b) => a.localeCompare(b)));
          setMentions(mentions);
          setOwner(userInfo.owner);
          setProperties(sort(userInfo.properties));
          setUserInfo(userInfo);
          setDescription(userInfo.description || "");
          setPicture(userInfo.picture || "");
          setName(userInfo.name || "");
          setEmail(userInfo.email || "");

          const hiddenProjectsProp = userInfo.properties.find((p) => p.name === "CODICENT_HIDDEN_PROJECTS");
          if (hiddenProjectsProp) {
            try {
              const hiddenProjects = JSON.parse(hiddenProjectsProp.value);
              setVisibleProjects(projects.filter((p) => hiddenProjects.indexOf(p) === -1));
            } catch {
              setVisibleProjects(projects);
            }
          } else {
            setVisibleProjects(projects);
          }
        })
        .catch(() => {
          window.history.replaceState({}, "", "/");
          window.location.reload();
        });
    },
    [nickname]
  );

  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (nickname && nickname !== "me") {
      update();
    }
  }, [nickname, update]);

  useEffect(() => {
    const property = properties.find((p) => p.name === "CO_ADMINS");
    if (property && property.value) {
      setCoAdmins(property.value.split(","));
    }
  }, [properties]);

  const setHiddenProjects = async (hiddenProjects: string[]) => {
    await addOrUpdateProperty("CODICENT_HIDDEN_PROJECTS", JSON.stringify(hiddenProjects), false);
    const visibleProjects = projects.filter((p) => hiddenProjects.indexOf(p) === -1);
    setVisibleProjects(visibleProjects);
  };

  const addOrUpdateProperty = async (name: string, value: string, isSecret?: boolean) => {
    try {
      await Api.addOrUpdateProjectProperty(nickname, { name, value, isSecret: isSecret === true } as Property);
      setProperties(
        sort([
          ...properties.filter((p) => p.name !== name),
          { name, value: isSecret ? "********" : value, isSecret: isSecret === true },
        ])
      );
    } catch (e) {
      console.log(e);
    }
  };

  const deleteProperty = (name: string) => {
    Api.deleteProjectProperty(nickname, name)
      .then(() => {
        setProperties(sort(properties.filter((p) => p.name !== name)));
      })
      .catch(() => {});
  };

  const addFollower = (followerNickname: string) => {
    if (followers.indexOf(followerNickname) === -1) {
      Api.addFollower(nickname, followerNickname).then(() => update(true));
    }
  };

  const removeFollower = (followerNickname: string) => {
    Api.removeFollower(nickname, followerNickname).then(() => update(true));
  };

  const updateProject = async (description: string, picture: string, name: string, email: string) => {
    await Api.addOrUpdateProject({ description, picture, nickname, name, email } as UserInfo).then(() => update(true));
  };

  const addProject = async (name: string) => {
    await Api.addOrUpdateProject({ description: "", picture: "", nickname: name, name, email: "" } as UserInfo).then(
      () => update(true)
    );
  };

  const cloneProject = async (name: string, newName: string) => {
    await Api.cloneProject(name, newName).then(() => update(true));
  };

  const deleteProject = async (name: string) => {
    await Api.deleteProject(name).then(() => update(true));
  };

  const getPropertyValue = (name: string) => {
    const property = properties.find((p) => p.name === name);
    return property ? property.value : null;
  };

  const refresh = () => {
    update(true);
  };

  return {
    followers,
    projects,
    mentions,
    owner,
    properties,
    addOrUpdateProperty,
    deleteProperty,
    nickname,
    userInfo,
    addFollower,
    removeFollower,
    description,
    picture,
    updateProject,
    name,
    email,
    getPropertyValue,
    visibleProjects,
    setHiddenProjects,
    addProject,
    cloneProject,
    refresh,
    deleteProject,
    coAdmins,
  };
};
