import {
  ChatMessage,
  UserInfo,
  Metadata,
  CodicentEvent,
  Property,
  TodoStatus,
  FileInfo,
  CodicentInfo,
  TodoItem,
  InstanceInfo,
  NodeInfo,
  NodeHierarchyInfo,
} from "./models";

export default class Api {
  static get _accessToken() {
    return new URLSearchParams(window.location.search).get("token") || localStorage.getItem("token");
  }

  static downloadFileUrl = (fileId: string, extension?: string, width?: number) => {
    // return `/app/DownloadFile?fileId=${fileId}&access_token=${Api._accessToken}`;
    return `/app/DownloadFile?fileId=${fileId}${extension ? "&extension=" + extension : ""}${
      width ? "&width=" + width : ""
    }`;
  };

  static profileImageUrl = (nickname: string) => {
    return `/app/GetProfileImage?nickname=${nickname}`;
  };

  static imageUrl = (fileId: string, width: number) => {
    return `/app/GetImage?fileId=${fileId}&width=${width}`;
  };

  static getAboutInfo = async () => {
    const response = await fetch(`/app/About`, {
      method: "GET",
      headers: [["Content-Type", "application/json; charset=utf-8"]],
    });
    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }
      throw new Error(`${response.statusText}`);
    }

    const info = await response.json();
    return info;
  };

  static getCodicentMessages = async (id: string) => {
    const response = await fetch(`/app/GetCodicentMessages?id=${id}`, {
      method: "GET",
      headers: [["Content-Type", "application/json; charset=utf-8"]],
    });
    if (!response.ok) {
      return [];
    }

    const json = await response.json();
    let messages = json as ChatMessage[];
    messages.forEach((m) => {
      // @ts-ignore
      m.createdAt = new Date(Date.parse(m.createdAt));
    });
    return messages;
  };

  static getMessageContent = async (id: string) => {
    const response = await fetch(`/app/GetMessageContent?id=${id}`, {
      method: "GET",
      headers: [
        ["Content-Type", "application/json; charset=utf-8"],
        ["Authorization", `Bearer ${Api._accessToken}`],
      ],
    });
    if (!response.ok) {
      return { content: "", url: "" };
    } else {
      const json = await response.json();
      return { content: json.content, url: json.url };
    }
  };

  static getMessagesContent = async (ids: string[]) => {
    const response = await fetch(`/app/GetMessagesContent`, {
      method: "POST",
      headers: [
        ["Content-Type", "application/json; charset=utf-8"],
        ["Authorization", `Bearer ${Api._accessToken}`],
      ],
      body: JSON.stringify(ids),
    });

    const json = await response.json();
    let messages = json as ChatMessage[];
    messages.forEach((m) => {
      // @ts-ignore
      m.createdAt = new Date(Date.parse(m.createdAt));
    });
    return messages;
  };

  static getSuggestions = async () => {
    const response = await fetch(`/app/GetSuggestions`, {
      method: "GET",
      headers: [
        ["Content-Type", "application/json; charset=utf-8"],
        ["Authorization", `Bearer ${Api._accessToken}`],
      ],
    });
    if (!response.ok) {
      return [];
    } else {
      const json = await response.json();
      return json as string[];
    }
  };

  static getTags = async (project: string) => {
    const response = await fetch(`/app/GetTags?project=${project}`, {
      method: "GET",
      headers: [
        ["Content-Type", "application/json; charset=utf-8"],
        ["Authorization", `Bearer ${Api._accessToken}`],
      ],
    });
    if (!response.ok) {
      return [];
    } else {
      const json = await response.json();
      return json as string[];
    }
  };

  static getChatMessages = async (
    start: number = 0,
    length: number = 20,
    search: string = "",
    afterTimestamp: Date | undefined = undefined,
    excludeHiddenMessages: boolean = false,
    signal: AbortSignal | undefined = undefined,
    includeContent: boolean = false
  ) => {
    const response = await fetch(
      `/app/GetChatMessages?start=${start}&length=${length}&search=${encodeURIComponent(search)}${
        afterTimestamp ? `&afterTimestamp=${afterTimestamp.toISOString()}` : ""
      }${excludeHiddenMessages ? `&excludeHiddenMessages=true` : ""}${includeContent ? `&includeContent=true` : ""}`,
      {
        method: "GET",
        headers: [
          ["Content-Type", "application/json; charset=utf-8"],
          ["Authorization", `Bearer ${Api._accessToken}`],
        ],
        signal,
      }
    );
    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
        throw new Error("Unauthorized");
      } else {
        throw new Error(`${response.statusText}`);
      }
    }

    const json = await response.json();
    let messages = json as ChatMessage[];
    messages.forEach((m) => {
      // @ts-ignore
      m.createdAt = new Date(Date.parse(m.createdAt));
    });
    return messages;
  };

  static addChatMessage = async (message: ChatMessage, impersonate?: string, token?: string) => {
    const response = await fetch(`/app/AddChatMessage${impersonate ? "?impersonate=" + impersonate : ""}`, {
      method: "POST",
      headers: [
        ["Content-Type", "application/json; charset=utf-8"],
        ["Authorization", `Bearer ${token || Api._accessToken}`],
      ],
      body: JSON.stringify(message),
    });
    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }
      throw new Error(`${response.statusText || "Failed to post message!"}`);
    }

    const json = await response.json();
    let id = json.id as string;
    return id;
  };

  static addFollowing = async (nickname: string) => {
    const response = await fetch(`/app/AddFollowing?nickname=${nickname}`, {
      method: "GET",
      headers: [
        ["Content-Type", "application/json; charset=utf-8"],
        ["Authorization", `Bearer ${Api._accessToken}`],
      ],
      //            body: JSON.stringify({ nickname })
    });
    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }
      throw new Error(`${response.statusText || "Failed to follow!"}`);
    }

    const json = await response.json();
    let ok = json as boolean;
    return ok;
  };

  static addFollower = async (nickname: string, followerNickname: string) => {
    const response = await fetch(`/app/AddFollower?nickname=${nickname}&followerNickname=${followerNickname}`, {
      method: "GET",
      headers: [
        ["Content-Type", "application/json; charset=utf-8"],
        ["Authorization", `Bearer ${Api._accessToken}`],
      ],
      //            body: JSON.stringify({ nickname })
    });
    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }
      throw new Error(`${response.statusText || "Failed to add follower!"}`);
    }

    const json = await response.json();
    let ok = json as boolean;
    return ok;
  };

  static removeFollower = async (nickname: string, followerNickname: string) => {
    const response = await fetch(`/app/RemoveFollower?nickname=${nickname}&followerNickname=${followerNickname}`, {
      method: "GET",
      headers: [
        ["Content-Type", "application/json; charset=utf-8"],
        ["Authorization", `Bearer ${Api._accessToken}`],
      ],
      //            body: JSON.stringify({ nickname })
    });
    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }
      throw new Error(`${response.statusText || "Failed to remove follower!"}`);
    }

    const json = await response.json();
    let ok = json as boolean;
    return ok;
  };

  static getFollowers = async (nickname: string) => {
    const response = await fetch(`/app/GetFollowers?nickname=${nickname}`, {
      method: "GET",
      headers: [
        ["Content-Type", "application/json; charset=utf-8"],
        ["Authorization", `Bearer ${Api._accessToken}`],
      ],
    });
    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }
      throw new Error(`${response.statusText || "Failed to get followers!"}`);
    }

    const json = await response.json();
    let followers = json as string[];
    return followers;
  };

  // static getFollowing = async () => {
  //    const response = await fetch(`/app/GetFollowing`, {
  //       method: 'GET',
  //       headers: [
  //          ['Content-Type', 'application/json; charset=utf-8'],
  //          ['Authorization', `Bearer ${Api._accessToken}`],
  //       ]
  //    });
  //    if (!response.ok) {
  //       if (response.status === 401) {
  //          // Unauthorized, clear the token?
  //          // token = '';
  //       }
  //       throw new Error(`${response.statusText || 'Failed to get following!'}`);
  //    }

  //    const json = await response.json();
  //    let following = json as string[];
  //    return following;
  // }

  static addOrUpdateUser = async (userInfo: UserInfo) => {
    // TODO: rename to SyncUser?
    const response = await fetch(`/app/AddOrUpdateUser`, {
      method: "POST",
      headers: [
        ["Content-Type", "application/json; charset=utf-8"],
        ["Authorization", `Bearer ${Api._accessToken}`],
      ],
      body: JSON.stringify(userInfo),
    });

    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }
      throw new Error(`${response.statusText}`);
    }

    const json = await response.json();
    let id = json as UserInfo;
    return id;
  };

  static requestRegistrationCode = async (phone: string) => {
    const response = await fetch(`/app/RequestRegistrationCode?phone=${encodeURIComponent(phone)}`, {
      method: "GET",
      headers: [
        ["Content-Type", "application/json; charset=utf-8"],
        ["Authorization", `Bearer ${Api._accessToken}`],
      ],
    });
    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }
      throw new Error(`${response.statusText || "Failed to get registration code!"}`);
    }
  };

  static registerUser = async (nickname: string, phone: string, code: string) => {
    const response = await fetch(`/app/RegisterUser`, {
      method: "POST",
      headers: [
        ["Content-Type", "application/json; charset=utf-8"],
        ["Authorization", `Bearer ${Api._accessToken}`],
      ],
      body: JSON.stringify({ nickname, phone, code }),
    });

    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }
      throw new Error(`${response.statusText}`);
    }

    const token = await response.text();
    return token;
  };

  static registerMicrosoftUser = async (nickname: string, email: string, userId: string, accessToken: string) => {
    const response = await fetch(`/app/RegisterMicrosoftUser`, {
      method: "POST",
      headers: [
        ["Content-Type", "application/json; charset=utf-8"],
        ["Authorization", `Bearer ${accessToken}`],
      ],
      body: JSON.stringify({ nickname, email, userId }),
    });

    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }
      throw new Error(`${response.statusText}`);
    }

    const token = await response.text();
    return token;
  };

  static registerAuth0User = async (nickname: string, email: string, userId: string, accessToken: string) => {
    const response = await fetch(`/app/RegisterAuth0User`, {
      method: "POST",
      headers: [
        ["Content-Type", "application/json; charset=utf-8"],
        ["Authorization", `Bearer ${accessToken}`],
      ],
      body: JSON.stringify({ nickname, email, userId }),
    });

    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }
      throw new Error(`${response.statusText}`);
    }

    const token = await response.text();
    return token;
  };

  static loginUser = async (phone: string, code: string) => {
    const response = await fetch(`/app/LoginUser`, {
      method: "POST",
      headers: [
        ["Content-Type", "application/json; charset=utf-8"],
        ["Authorization", `Bearer ${Api._accessToken}`],
      ],
      body: JSON.stringify({ phone, code }),
    });

    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }
      throw new Error(`${response.statusText}`);
    }

    const token = await response.text();
    return token;
  };

  static loginMicrosoftUser = async (userId: string, accessToken: string) => {
    const response = await fetch(`/app/LoginMicrosoftUser`, {
      method: "POST",
      headers: [
        ["Content-Type", "application/json; charset=utf-8"],
        ["Authorization", `Bearer ${accessToken}`],
      ],
      body: JSON.stringify({ userId }),
    });

    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }
      throw new Error(`${response.statusText}`);
    }

    const token = await response.text();
    return token;
  };

  static loginAuth0User = async (userId: string, accessToken: string) => {
    // TODO: the same as microsoft user?
    const response = await fetch(`/app/LoginAuth0User`, {
      method: "POST",
      headers: [
        ["Content-Type", "application/json; charset=utf-8"],
        ["Authorization", `Bearer ${accessToken}`],
      ],
      body: JSON.stringify({ userId }),
    });

    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }
      throw new Error(`${response.statusText}`);
    }

    const token = await response.text();
    return token;
  };

  static addOrUpdateProject = async (userInfo: UserInfo) => {
    const response = await fetch(`/app/AddOrUpdateProject`, {
      method: "POST",
      headers: [
        ["Content-Type", "application/json; charset=utf-8"],
        ["Authorization", `Bearer ${Api._accessToken}`],
      ],
      body: JSON.stringify(userInfo),
    });

    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }
      throw new Error(`${response.statusText}`);
    }

    const json = await response.json();
    let id = json as UserInfo;
    return id;
  };

  static cloneProject = async (project: string, newProject: string) => {
    const response = await fetch(`/app/CloneProject?project=${project}&newProject=${newProject}`, {
      method: "GET",
      headers: [["Authorization", `Bearer ${Api._accessToken}`]],
    });

    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }
      throw new Error(`${response.statusText}`);
    }

    const json = await response.json();
    let ok = json as boolean;
    return ok;
  };

  static addOrUpdateProjectProperty = async (project: string, property: Property) => {
    const response = await fetch(
      `/app/AddOrUpdateProjectProperty?project=${encodeURIComponent(project)}&key=${
        property.name
      }&value=${encodeURIComponent(property.value)}&isSecret=${property.isSecret ? "true" : "false"}`,
      {
        method: "GET",
        headers: [
          ["Content-Type", "application/json; charset=utf-8"],
          ["Authorization", `Bearer ${Api._accessToken}`],
        ],
      }
    );
    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }
      throw new Error(`${response.statusText || "Failed to set project property!"}`);
    }
  };

  static deleteProjectProperty = async (project: string, name: string) => {
    const response = await fetch(`/app/DeleteProjectProperty?project=${encodeURIComponent(project)}&key=${name}`, {
      method: "GET",
      headers: [
        ["Content-Type", "application/json; charset=utf-8"],
        ["Authorization", `Bearer ${Api._accessToken}`],
      ],
    });
    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }
      throw new Error(`${response.statusText || "Failed to delete project property!"}`);
    }
  };

  static generateApiToken = async (project: string) => {
    const response = await fetch(`/api/GenerateApiToken?project=${project}`, {
      method: "GET",
      headers: [
        ["Content-Type", "application/json; charset=utf-8"],
        ["Authorization", `Bearer ${Api._accessToken}`],
      ],
    });
    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }
      throw new Error(`${response.statusText}`);
    }
    const token = await response.text();
    return token;
  };

  static getUserInfo = async (nickname: string, force = false) => {
    const response = await fetch(`/app/GetUserInfo?nickname=${nickname}${force ? "&t=" + Date.now() : ""}`, {
      method: "GET",
      headers: [
        ["Content-Type", "application/json; charset=utf-8"],
        ["Authorization", `Bearer ${Api._accessToken}`],
      ],
    });
    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        throw new Error("Unauthorized");
      } else {
        throw new Error(`${response.statusText}`);
      }
    }
    const userInfo = await response.json();
    return userInfo as UserInfo;
  };

  static getNickname = async (userId: string) => {
    const response = await fetch(`/app/GetNickname?userId=${userId}`, {
      method: "GET",
      headers: [
        ["Content-Type", "application/json; charset=utf-8"],
        ["Authorization", `Bearer ${Api._accessToken}`],
      ],
    });
    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }
      throw new Error(`${response.statusText}`);
    }

    const nickname = await response.text();
    return nickname;
  };

  static extractMetadata = async (url: string) => {
    const response = await fetch(`/app/ExtractMetaData?url=${encodeURIComponent(url)}`, {
      method: "GET",
      headers: [
        ["Content-Type", "application/json; charset=utf-8"],
        ["Authorization", `Bearer ${Api._accessToken}`],
      ],
    });

    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // Api._accessToken = '';
      }

      throw new Error(`${response.statusText}`);
    }

    const metadata = (await response.json()) as Metadata;
    return metadata;
  };

  static nicknameExists = async (nickname: string) => {
    const response = await fetch(`/app/NicknameExists?nickname=${encodeURIComponent(nickname)}`, {
      method: "GET",
      headers: [["Content-Type", "application/json; charset=utf-8"]],
    });

    if (!response.ok) {
      return false;
    }

    const exists = (await response.json()) as boolean;
    return exists;
  };

  static async uploadFile(filename: string, formData: FormData) {
    const response = await fetch(`/app/UploadFile?filename=${encodeURIComponent(filename)}`, {
      method: "POST",
      headers: [["Authorization", `Bearer ${this._accessToken}`]],
      body: formData,
    });

    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token must be old
        // token = '';
      }

      throw new Error(`${response.statusText}`);
    }

    const fileGuid = await response.json();
    return fileGuid as string;
  }

  static async getProfileImageUrl(nickname: string) {
    const response = await fetch(`/app/GetProfileImageUrl?nickname=${nickname}`, {
      method: "GET",
    });

    const url = await response.text();
    return url;
  }

  static addEvent = async (event: CodicentEvent) => {
    const response = await fetch(`/app/AddEvent`, {
      method: "POST",
      headers: [
        ["Content-Type", "application/json; charset=utf-8"],
        ["Authorization", `Bearer ${Api._accessToken}`],
      ],
      body: JSON.stringify(event),
    });
    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }
      throw new Error(`${response.statusText || "Failed to add event!"}`);
    }
    // const json = await response.json();
    // let id = json as string;
    // return id;
  };

  static downloadBackup = async (nickname: string) => {
    const response = await fetch(`/app/DownloadBackup?nickname=${nickname}`, {
      method: "GET",
      headers: [["Authorization", `Bearer ${Api._accessToken}`]],
    });

    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }
      throw new Error(`${response.statusText}`);
    }

    return response.blob();
  };

  static downloadCodicentFile = async (filename: string) => {
    const response = await fetch(`/app/DownloadCodicentFile?filename=${filename}`, {
      method: "GET",
    });

    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }
      // throw new Error(`${response.statusText}`);
      return null;
    }

    return response.text();
  };

  static requestInvite = async (forUserNickname: string, toProjectNickname: string) => {
    const response = await fetch(
      `/app/CreateInvitationOrRequest?forUserNickname=${forUserNickname}&toProjectNickname=${toProjectNickname}`,
      {
        method: "GET",
        headers: [["Authorization", `Bearer ${Api._accessToken}`]],
      }
    );

    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }
      // throw new Error(`${response.statusText}`);
      console.warn(`requestInvite() => ${response.statusText}`);
      return false;
    }

    return true;
  };

  static createInvitationLink = async (
    forUserNickname: string,
    toProjectNickname: string,
    withUserTemplate: string,
    phone: string,
    email: string
  ) => {
    const response = await fetch(
      `/app/CreateInvitationLink?forUserNickname=${forUserNickname}&toProjectNickname=${toProjectNickname}&withUserTemplate=${withUserTemplate}&phone=${encodeURIComponent(
        phone
      )}&email=${encodeURIComponent(email)}`,
      {
        method: "GET",
        headers: [["Authorization", `Bearer ${Api._accessToken}`]],
      }
    );

    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }

      // throw new Error(`${response.statusText}`);
      console.warn(`createInvitationLink() => ${response.statusText}`);
      return false;
    }

    return true;
  };

  static acceptInvitationLink = async (code: string) => {
    const response = await fetch(`/app/AcceptInvitationLink?code=${code}`, {
      method: "GET",
    });

    if (!response.ok) {
      console.warn(`acceptInvitationLink() => ${response.statusText}`);
      return false;
    }

    return true;
  };

  static getTodoItems = async (project: string) => {
    const response = await fetch(`/app/GetTodoItems?project=${project}&length=1000`, {
      method: "GET",
      headers: [["Authorization", `Bearer ${Api._accessToken}`]],
    });

    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }

      return [];
    }

    const json = await response.json();
    let items = json as TodoItem[];
    items.forEach((m) => {
      // @ts-ignore
      m.createdAt = new Date(Date.parse(m.createdAt));
      // @ts-ignore
      m.updatedAt = new Date(Date.parse(m.updatedAt));
    });

    return items;
  };

  static getTodoStatus = async (messageId: string) => {
    const response = await fetch(`/app/GetTodoStatus?messageId=${messageId}`, {
      method: "GET",
      headers: [["Authorization", `Bearer ${Api._accessToken}`]],
    });

    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }
      // throw new Error(`${response.statusText}`);
      console.warn(`getTodoStatus() => ${response.statusText}`);
      return "unknown";
    }

    return await response.text();
  };

  static updateTodoStatus = async (messageId: string, status: TodoStatus) => {
    const response = await fetch(`/app/UpdateTodoStatus?messageId=${messageId}&status=${status}`, {
      method: "GET",
      headers: [["Authorization", `Bearer ${Api._accessToken}`]],
    });

    if (!response.ok) {
      console.warn(`updateTodoStatus() => ${response.statusText}`);
      return false;
    }

    const json = await response.json();
    const ok = json as boolean;
    return ok;
  };

  static getInvitationOrRequestStatus = async (forUserNickname: string, toProjectNickname: string) => {
    const response = await fetch(
      `/app/GetInvitationOrRequestStatus?forUserNickname=${forUserNickname}&toProjectNickname=${toProjectNickname}`,
      {
        method: "GET",
        headers: [["Authorization", `Bearer ${Api._accessToken}`]],
      }
    );

    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }
      // throw new Error(`${response.statusText}`);
      console.warn(`requestInvite() => ${response.statusText}`);
      return "unknown";
    }

    const status = await response.text();
    return status;
  };

  static acceptInvitation = async (forUserNickname: string, toProjectNickname: string) => {
    const response = await fetch(
      `/app/AcceptInvitation?forUserNickname=${forUserNickname}&toProjectNickname=${toProjectNickname}&t=${Date.now()}}`,
      {
        method: "GET",
        headers: [["Authorization", `Bearer ${Api._accessToken}`]],
      }
    );

    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }
      // throw new Error(`${response.statusText}`);
      console.warn(`acceptInvitation() => ${response.statusText}`);
      return false;
    }

    const json = await response.json();
    const ok = json as boolean;
    return ok;
  };

  static acceptRequest = async (forUserNickname: string, toProjectNickname: string) => {
    const response = await fetch(
      `/app/AcceptRequest?forUserNickname=${forUserNickname}&toProjectNickname=${toProjectNickname}`,
      {
        method: "GET",
        headers: [["Authorization", `Bearer ${Api._accessToken}`]],
      }
    );

    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }
      // throw new Error(`${response.statusText}`);
      console.warn(`acceptRequest() => ${response.statusText}`);
      return false;
    }

    const json = await response.json();
    const ok = json as boolean;
    return ok;
  };

  static rejectRequest = async (forUserNickname: string, toProjectNickname: string) => {
    const response = await fetch(
      `/app/RejectRequest?forUserNickname=${forUserNickname}&toProjectNickname=${toProjectNickname}`,
      {
        method: "GET",
        headers: [["Authorization", `Bearer ${Api._accessToken}`]],
      }
    );

    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }
      // throw new Error(`${response.statusText}`);
      console.warn(`rejectRequest() => ${response.statusText}`);
      return false;
    }

    const json = await response.json();
    const ok = json as boolean;
    return ok;
  };

  static getStatistics = async () => {
    const response = await fetch("/app/GetStatistics", {
      method: "GET",
      headers: [["Authorization", `Bearer ${Api._accessToken}`]],
    });

    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }
      // throw new Error(`${response.statusText}`);
      return {};
    }

    const json = await response.json();
    return json;
  };

  static getNumberOfMessages = async () => {
    const response = await fetch("/app/GetNumberOfMessages", {
      method: "GET",
      headers: [["Authorization", `Bearer ${Api._accessToken}`]],
    });

    if (!response.ok) {
      return 0;
    }

    const json = await response.json();
    return json as number;
  };

  static getFormPage = async (project: string, page: string) => {
    const response = await fetch(
      `/app/GetFormPage?project=${encodeURIComponent(project)}&page=${encodeURIComponent(page)}`,
      {
        method: "GET",
        headers: [
          ["Content-Type", "application/json; charset=utf-8"],
          ["Authorization", `Bearer ${Api._accessToken}`],
        ],
      }
    );
    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }
      throw new Error("Unauthorized");
    }

    const json = await response.json();
    let messages = json as ChatMessage[];
    messages.forEach((m) => {
      // @ts-ignore
      m.createdAt = new Date(Date.parse(m.createdAt));
    });
    return messages;
  };

  static addScheduleItem = async (message: string, startAt: Date) => {
    const response = await fetch("/app/Schedule", {
      method: "POST",
      headers: [
        ["Content-Type", "application/json; charset=utf-8"],
        ["Authorization", `Bearer ${Api._accessToken}`],
      ],
      body: JSON.stringify({
        message: message,
        startAt: startAt.toISOString(),
      }),
    });
    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }
      throw new Error("Unauthorized");
    }

    const json = await response.json();
    const ok = json as boolean;
    return ok;
  };

  static updateMessageState = async (messageId: string, state: string) => {
    const response = await fetch(`/app/UpdateMessageState?messageId=${messageId}&state=${state}`, {
      method: "GET",
      headers: [
        // ["Content-Type", "application/json; charset=utf-8"],
        ["Authorization", `Bearer ${Api._accessToken}`],
      ],
    });
    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }
      // throw new Error("Unauthorized");
      return false;
    }
    return true;
  };

  static getMessageState = async (messageId: string, abort?: AbortController) => {
    const response = await fetch(`/app/GetMessageState?messageId=${messageId}`, {
      method: "GET",
      headers: [
        ["Content-Type", "application/json; charset=utf-8"],
        ["Authorization", `Bearer ${Api._accessToken}`],
      ],
      signal: abort ? abort.signal : undefined,
    });
    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }
      return "";
    }

    const state = await response.text();
    return state;
  };

  static getFileInfo = async (fileId: string) => {
    const response = await fetch(`/app/GetFileInfo?fileId=${fileId}`, {
      method: "GET",
      headers: [
        // ["Content-Type", "application/json; charset=utf-8"],
        ["Authorization", `Bearer ${Api._accessToken}`],
      ],
    });
    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }
      return undefined;
    }

    const fileInfo = (await response.json()) as FileInfo;
    return fileInfo;
  };

  static getFiles = async (project: string) => {
    const response = await fetch(`/app/GetFiles?project=${project}`, {
      method: "GET",
      headers: [
        // ["Content-Type", "application/json; charset=utf-8"],
        ["Authorization", `Bearer ${Api._accessToken}`],
      ],
    });
    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }
      return undefined;
    }

    let files = (await response.json()) as FileInfo[];
    files.forEach((f) => {
      // @ts-ignore
      f.createdAt = new Date(Date.parse(f.createdAt));
    });

    return files;
  };

  static downloadMembersAsVcf = async (project: string) => {
    const response = await fetch(`/app/DownloadMembersAsVcf?project=${project}`, {
      method: "GET",
      headers: [["Authorization", `Bearer ${Api._accessToken}`]],
    });
    if (!response.ok) {
      return undefined;
    }

    return response.blob();
  };

  static downloadFormAnswersCsv = async (project: string, page: string, beforeTimestamp: Date) => {
    const response = await fetch(
      `/app/GetFormAnswersAsCsv?project=${project}&page=${page}&beforeTimestamp=${beforeTimestamp.toISOString()}`,
      {
        method: "GET",
        headers: [["Authorization", `Bearer ${Api._accessToken}`]],
      }
    );
    if (!response.ok) {
      return undefined;
    }

    return response.blob();
  };

  static getAiReply = async (message: string) => {
    const response = await fetch(`/ai/GetAiReply?message=${message}`, {
      // TODO: switch to GetAi2Reply
      method: "GET",
      headers: [
        // ["Content-Type", "application/json; charset=utf-8"],
        ["Authorization", `Bearer ${Api._accessToken}`],
      ],
    });
    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }
      return undefined;
    }

    const reply = await response.text();
    return reply;
  };

  static getAiReply2 = async (message: string, project: string) => {
    const response = await fetch(`/app/GetAiReply`, {
      // TODO: switch to GetAi2Reply
      method: "POST",
      headers: [
        ["Content-Type", "application/json; charset=utf-8"],
        ["Authorization", `Bearer ${Api._accessToken}`],
      ],
      body: JSON.stringify({ message, project }),
    });
    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }
      return undefined;
    }

    const reply = await response.text();
    return reply;
  };

  static getAiChatReply = async (message: string, project: string, messageId?: string) => {
    // TODO: remove this function?
    // TODO: switch to GetAi2ChatReply
    const response = await fetch(
      `/app/GetAiChatReply?message=${message}&project=${project}${messageId ? "&messageId=" + messageId : ""}`,
      {
        method: "GET",
        headers: [
          // ["Content-Type", "application/json; charset=utf-8"],
          ["Authorization", `Bearer ${Api._accessToken}`],
        ],
      }
    );
    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }
      return undefined;
    }

    const json = await response.json();
    let reply = json as ChatMessage;
    // @ts-ignore
    reply.createdAt = new Date(Date.parse(reply.createdAt));
    return reply;
  };

  static postAiChatReply = async (reply: string, project: string, messageId: string) => {
    // TODO: switch to postAi2ChatReply
    const response = await fetch(`/ai/PostAiChatReply?reply=${reply}&project=${project}&messageId=${messageId}`, {
      method: "GET",
      headers: [
        // ["Content-Type", "application/json; charset=utf-8"],
        ["Authorization", `Bearer ${Api._accessToken}`],
      ],
    });
    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }
      return false;
    }

    return true;
  };

  static getViewFileUrl = async (fileId: string) => {
    const response = await fetch(`/app/CreateFileSecret?fileId=${fileId}`, {
      method: "GET",
      headers: [
        // ["Content-Type", "application/json; charset=utf-8"],
        ["Authorization", `Bearer ${Api._accessToken}`],
      ],
    });
    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }
      return undefined;
    }

    const secret = await response.text();
    const protocol = window.location.protocol;
    const host = window.location.host;
    const url = `${protocol}//${host}/app/ViewFile?secret=${encodeURIComponent(secret)}`;
    return url;
  };

  static getMessageHistory = async (messageId: string, token?: string) => {
    const response = await fetch(`/api/GetMessageHistory?messageId=${messageId}`, {
      method: "GET",
      headers: [["Authorization", `Bearer ${token || Api._accessToken}`]],
    });
    if (!response.ok) {
      if (response.status === 401) {
        throw new Error("Unauthorized");
      }

      return [];
    }

    const json = await response.json();
    let messages = json as ChatMessage[];
    messages.forEach((m) => {
      // @ts-ignore
      m.createdAt = new Date(Date.parse(m.createdAt));
    });
    return messages;
  };

  static getRootMessage = async (messageId: string) => {
    const response = await fetch(`/app/GetRootMessage?id=${messageId}`, {
      method: "GET",
      headers: [["Authorization", `Bearer ${Api._accessToken}`]],
    });
    if (!response.ok) {
      return null;
    }

    const json = await response.json();
    let message = json as ChatMessage;
    // @ts-ignore
    message.createdAt = new Date(Date.parse(message.createdAt));
    return message;
  };

  static queryChatMessages = async (start: number = 0, length: number = 20, project: string, query: string) => {
    const response = await fetch(
      `/app/QueryChatMessages?start=${start}&length=${length}&project=${encodeURIComponent(
        project
      )}&query=${encodeURIComponent(query)}`,
      {
        method: "GET",
        headers: [
          ["Content-Type", "application/json; charset=utf-8"],
          ["Authorization", `Bearer ${Api._accessToken}`],
        ],
      }
    );
    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
        throw new Error("Unauthorized");
      } else {
        throw new Error(`${response.statusText}`);
      }
    }

    const json = await response.json();
    let messages = json as ChatMessage[];
    messages.forEach((m) => {
      // @ts-ignore
      m.createdAt = new Date(Date.parse(m.createdAt));
    });
    return messages;
  };

  static getLastMessageByProjectAndTag = async (project: string, tag: string) => {
    const response = await fetch(`/app/GetLastMessageByProjectAndTag?project=${project}&tag=${tag}`, {
      method: "GET",
      headers: [["Authorization", `Bearer ${Api._accessToken}`]],
    });
    if (!response.ok) {
      return "";
    }

    const content = await response.text();
    return content;
  };

  static getLastMessageByProjectAndType = async (project: string, type: string) => {
    const response = await fetch(`/app/GetLastMessageByProjectAndType?project=${project}&type=${type}`, {
      method: "GET",
      headers: [["Authorization", `Bearer ${Api._accessToken}`]],
    });
    if (!response.ok) {
      return "";
    }

    try {
      const message = (await response.json()) as ChatMessage;
      return message;
    } catch {
      return null;
    }
  };

  static getChatProjects = async () => {
    const response = await fetch(`/app/GetChatProjects`, {
      method: "GET",
      headers: [["Authorization", `Bearer ${Api._accessToken}`]],
    });

    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }
      throw new Error(`${response.statusText}`);
    }

    const json = await response.json();
    const projects = json as UserInfo[];
    return projects;
  };

  static registerDevice = async (deviceToken: string) => {
    try {
      await fetch(`/app/RegisterDevice?deviceToken=${encodeURIComponent(deviceToken)}`, {
        method: "GET",
        headers: [["Authorization", `Bearer ${Api._accessToken}`]],
      });
    } catch (e) {
      console.error(e);
    }
  };

  static getDeviceTokens = async () => {
    const response = await fetch(`/app/GetDeviceTokens`, {
      method: "GET",
      headers: [["Authorization", `Bearer ${Api._accessToken}`]],
    });

    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }
      throw new Error(`${response.statusText}`);
    }

    const json = await response.json();
    const deviceTokens = json as string[];
    return deviceTokens;
  };

  private static findDataMessagesInternal = async (project: string, tags: string[], search?: string) => {
    const response = await fetch(
      `/app/FindDataMessages?project=${encodeURIComponent(project)}${
        search ? "&search=" + encodeURIComponent(search) : ""
      }`,
      {
        method: "POST",
        headers: [
          ["Content-Type", "application/json; charset=utf-8"],
          ["Authorization", `Bearer ${Api._accessToken}`],
        ],
        body: JSON.stringify({ tags }),
      }
    );
    if (!response.ok) {
      if (response.status === 401) {
        return [];
      } else {
        throw new Error(`${response.statusText}`);
      }
    }

    const json = await response.json();
    let data = json as any[];
    return data;
  };

  static findDataMessages = async (project: string, tags: string[], search?: string) => {
    const data = await this.findDataMessagesInternal(project, tags, search);
    return data.map((m) => m.data);
  };

  static findDataMessagesWithId = async (project: string, tags: string[], search?: string) => {
    const data = await this.findDataMessagesInternal(project, tags, search);
    return data.map((m) => ({ ...m.data, _id: m.id, _tags: m.tags }));
  };

  static getDataMessage = async (project: string, tags: string[], search?: string) => {
    const response = await fetch(
      `/app/GetDataMessage?project=${encodeURIComponent(project)}${
        search ? "&search=" + encodeURIComponent(search) : ""
      }`,
      {
        method: "POST",
        headers: [
          ["Content-Type", "application/json; charset=utf-8"],
          ["Authorization", `Bearer ${Api._accessToken}`],
        ],
        body: JSON.stringify({ tags }),
      }
    );
    if (!response.ok) {
      if (response.status === 401) {
        return {
          id: null,
          data: {},
          fileId: null,
        };
      } else {
        throw new Error(`${response.statusText}`);
      }
    }

    const json = await response.json();
    let data = json as any;
    return data.data;
  };

  static getChildNodes = async (codicent: string, type: string, id: string) => {
    const response = await fetch(`/app/GetChildNodes?codicent=${codicent}&type=${type}&id=${id}`, {
      method: "GET",
      headers: [["Authorization", `Bearer ${Api._accessToken}`]],
    });
    if (!response.ok) {
      return [];
    }

    const json = await response.json();
    let nodes = json as NodeInfo[];
    return nodes;
  };

  static getNode = async (nodeId: string) => {
    const response = await fetch(`/app/GetNode?nodeId=${nodeId}`, {
      method: "GET",
      headers: [["Authorization", `Bearer ${Api._accessToken}`]],
    });
    if (!response.ok) {
      return null;
    }

    const json = await response.json();
    let node = json as NodeInfo;
    return node;
  };

  static updateNode = async (nodeId: string, content: string, refNodeId?: string) => {
    const response = await fetch(`/app/UpdateNode?nodeId=${nodeId}${refNodeId ? "&refNodeId=" + refNodeId : ""}`, {
      method: "POST",
      headers: [
        ["Content-Type", "application/json; charset=utf-8"],
        ["Authorization", `Bearer ${Api._accessToken}`],
      ],
      body: JSON.stringify({ content }),
    });
    if (!response.ok) {
      return null;
    }

    const json = await response.json();
    let node = json as NodeInfo;
    return node;
  };

  static addNode = async (codicent: string, content: string, type: string, refNodeId?: string) => {
    const response = await fetch(
      `/app/AddNode?codicent=${codicent}&type=${type}${refNodeId ? "&refNodeId=" + refNodeId : ""}`,
      {
        method: "POST",
        headers: [
          ["Content-Type", "application/json; charset=utf-8"],
          ["Authorization", `Bearer ${Api._accessToken}`],
        ],
        body: JSON.stringify({ content }),
      }
    );
    if (!response.ok) {
      return null;
    }

    const json = await response.json();
    let node = json as NodeInfo;
    return node;
  };

  static deleteNode = async (nodeId: string) => {
    const response = await fetch(`/app/DeleteNode?nodeId=${nodeId}`, {
      method: "GET",
      headers: [["Authorization", `Bearer ${Api._accessToken}`]],
    });
    if (!response.ok) {
      return null;
    }

    const json = await response.json();
    let ok = json as boolean;
    return ok;
  };

  static getNodeHierarchy = async (codicent: string) => {
    const response = await fetch(`/app/GetNodeHierarchy?codicent=${codicent}`, {
      method: "GET",
      headers: [["Authorization", `Bearer ${Api._accessToken}`]],
    });
    if (!response.ok) {
      return [];
    }
    const json = await response.json();
    let info = json as NodeHierarchyInfo[];
    return info;
  };

  static hideMessage = async (id: string) => {
    const response = await fetch(`/app/HideMessage?id=${id}`, {
      method: "GET",
      headers: [["Authorization", `Bearer ${Api._accessToken}`]],
    });
    if (!response.ok) {
      return false;
    }

    return true;
  };

  static deleteProject = async (project: string) => {
    const response = await fetch(`/app/DeleteProject?project=${project}`, {
      method: "GET",
      headers: [["Authorization", `Bearer ${Api._accessToken}`]],
    });
    if (!response.ok) {
      return false;
    }

    return true;
  };

  static toggleFileIndex = async (project: string, fileId: string) => {
    const response = await fetch(`/app/ToggleFileIndex?project=${project}&fileId=${fileId}`, {
      method: "GET",
      headers: [["Authorization", `Bearer ${Api._accessToken}`]],
    });
    if (!response.ok) {
      return false;
    }

    return true;
  };

  static getCodicents = async () => {
    const response = await fetch(`/app/GetCodicents`, {
      method: "GET",
      headers: [["Authorization", `Bearer ${Api._accessToken}`]],
    });
    if (!response.ok) {
      if (response.status === 401) {
        return [];
      } else {
        throw new Error(`${response.statusText}`);
      }
    }

    const json = await response.json();
    let data = json as CodicentInfo[];
    return data;
  };

  static getInstanceInfo = async () => {
    const response = await fetch(`/app/GetInstanceInfo`, {
      method: "GET",
      headers: [["Authorization", `Bearer ${Api._accessToken}`]],
    });
    if (!response.ok) {
      if (response.status === 401) {
        return {} as InstanceInfo;
      } else {
        throw new Error(`${response.statusText}`);
      }
    }

    const json = await response.json();
    let data = json as InstanceInfo;
    return data;
  };

  static autoTagMessage = async (project: string, content: string) => {
    const response = await fetch(`/app/AutoTagMessage`, {
      method: "POST",
      headers: [
        ["Authorization", `Bearer ${Api._accessToken}`],
        ["Content-Type", "application/json; charset=utf-8"],
      ],
      body: JSON.stringify({ project, content }),
    });

    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }
      throw new Error(`${response.statusText}`);
    }

    const tag = await response.text();
    return tag;
  };
}
