import React from 'react';
import { Text, Stack, Label, Dialog as DialogComponent } from "office-ui-fabric-react";

export const PageTitle = (props: any) => {
    return <div><Text variant={'xxLarge'}>{props.children}</Text></div>;
}

export const ParagraphTitle = (props: any) => {
    return <div><Text variant={'xLarge'}>{props.children}</Text></div>;
}

export const ParagraphText = (props: any) => {
    return <div><Text>{props.children}</Text></div>;
}

export const ParagraphLabel = (props: any) => {
    return <Label>{props.children}</Label>;
}

export const StackVert = (props: any) => {
    return <Stack
        tokens={{ childrenGap: 15 }}
        styles={{ root: { width: "80%" } }}>{props.children}
        {/* TODO: Should we add the following ? style={{ marginTop: 10 }} */}
    </Stack>;
}

export const Page = (props: any) => <div style={{ height: '100%', width: '100%' }}>{props.children}</div>;

export const Dialog = (props: any) => <DialogComponent hidden={false} { ...props } />;