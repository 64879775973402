import React, { useEffect, useState } from "react";
import {
  mergeStyleSets,
  IconButton,
  Spinner,
  SpinnerSize,
  IIconStyles,
  IStyleFunctionOrObject,
  IIconStyleProps,
  IButtonStyles,
} from "office-ui-fabric-react";
import { FileHelpers } from "../file";

const styles = mergeStyleSets({
  imageUpload: {
    display: "none",
  },
});

interface UploadFileProps {
  onFileUploaded: (fileIds: string[]) => void;
  iconName?: string;
  accept?: string;
  capture?: undefined | "environment";
  title?: string;
  onUploading: (uploading: boolean) => void;
  multiple?: boolean;
  iconButtonStyles?: IStyleFunctionOrObject<IIconStyleProps, IIconStyles>;
  buttonStyles?: IButtonStyles;
  id?: string;
}

export const UploadFile = (props: UploadFileProps) => {
  // const [error, setError] = useState("");
  const [uploading, setUploading] = useState(false);
  const { onFileUploaded, accept, iconName, title, multiple, iconButtonStyles, onUploading, capture, buttonStyles } =
    props;
  const fileuploadRef = React.createRef<HTMLInputElement>();

  useEffect(() => {
    onUploading(uploading);
  }, [uploading, onUploading]);

  const _onChangeFile = async () => {
    try {
      if (document && fileuploadRef.current) {
        const fileupload = fileuploadRef.current;
        let filesUploaded = [];
        if (fileupload.files !== null) {
          for (let i = 0; i < fileupload.files.length; i++) {
            let filename: string = fileupload.files[i].name;
            let index = filename.lastIndexOf("\\");
            if (index !== -1) {
              filename = filename.substring(index + 1);
            }

            const fileGuid = await _uploadFile(filename, fileupload.files[i]);
            if (fileGuid) {
              filesUploaded.push(fileGuid);
            }
          }
        }

        if (filesUploaded.length > 0) {
          onFileUploaded(filesUploaded);
        }
      }
    } catch {}
  };

  const _uploadFile = (filename: string, file: File) => {
    let formData = new FormData();
    formData.append("file", file);
    setUploading(true);
    return FileHelpers.uploadFile(filename, formData)
      .then((fileGuid) => {
        setUploading(false);
        return fileGuid;
      })
      .catch((error) => {
        setUploading(false);
        return null;
      });
  };

  return (
    <div id={props.id}>
      {!uploading && (
        <div>
          <label htmlFor="file-input">
            <IconButton
              title={title}
              iconProps={{ iconName: iconName || "Photo2Add", styles: iconButtonStyles }}
              styles={buttonStyles}
              onClick={(ev) => {
                ev.preventDefault();
                fileuploadRef.current!.click();
              }}
            />
          </label>
          <input
            id="file-input"
            className={styles.imageUpload}
            ref={fileuploadRef}
            title="Upload"
            required={false}
            type="file"
            name="files"
            multiple={multiple}
            accept={accept}
            capture={capture}
            onChange={_onChangeFile}
          />
        </div>
      )}
      {uploading && <Spinner title="Uploading..." size={SpinnerSize.xSmall} style={{ marginTop: "10px" }} />}
    </div>
    // {error !== "" && (
    //   <MessageBar messageBarType={MessageBarType.error} isMultiline={false}>
    //     {error}
    //   </MessageBar>
    // )}
  );
};
