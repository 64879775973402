/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from "react";
import Api from "./api";
import AuthService from "./AuthService";
import { getBookmarks, getHiddenMessages, hideMessage, toggleBookmark, undoHideMessage } from "./helpers";
import { ChatMessage, Type, UserInfo } from "./models";
import { ProjectInfo, useObserveProject } from "./useObserveProject";

const getSavedMessages = (): ChatMessage[] => {
  const json = localStorage.getItem("messages") || "[]";
  const message = JSON.parse(json);
  return message.map((m: ChatMessage) => ({
    ...m,
    createdAt: new Date(m.createdAt),
  }));
};

const saveMessages = (messages: ChatMessage[]) => {
  try {
    localStorage.setItem("messages", JSON.stringify(messages));
  } catch {
    localStorage.setItem(
      "messages",
      JSON.stringify(
        messages.map((m) => ({
          ...m,
          content: !m.content || m.content.length > 200 ? undefined : m.content,
        }))
      )
    );
  }
};

// const mergeMessages = (messages: ChatMessage[], newMessages: ChatMessage[]) => {
//   const merged = [...messages, ...newMessages];
//   const unique = merged.filter(
//     (v, i, a) => a.findIndex((t) => t.id === v.id) === i
//   );
//   return unique;
// };

export interface ObserveUserProps {
  messages: ChatMessage[];
  nickname: string;
  busy: boolean;
  isAuthenticated: boolean;
  authService: AuthService;
  sendMessage: (
    text: string,
    parentId?: string,
    type?: Type,
    properties?: any,
    token?: string
  ) => Promise<string | undefined>;
  error: unknown;
  refreshMessages: () => Promise<void>;
  refreshUserInfo: () => Promise<void>;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  getMessages: () => void;
  token: string | null;
  userInfo: UserInfo;
  search: string;
  info: ProjectInfo;
  removeMessage: (id: string) => void;
  canUndoRemovedMessages: boolean;
  undoRemovedMessages: () => void;
  hasNewMessages: boolean;
  getVariable: (name: string) => string | null;
  isOffline: boolean;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  suggestions: string[];
  chatProjects: UserInfo[];
  project: ProjectInfo;
  setCurrentProject: (project: string) => void;
  checkForNewMessages: () => void;
  toggleMessageBookmark: (id: string) => void;
  log: string | undefined;
  setLog: React.Dispatch<React.SetStateAction<string | undefined>>;
}

// TODO: replace handleMessages flag by breaking out message handling into useObserveMessages
export const useObserveUser = (handleMessages: boolean, initialSearch?: string): ObserveUserProps => {
  const maxMessagesPerApiCall = 200;
  const [messages, setMessages] = useState<ChatMessage[]>(getSavedMessages());
  const isUpdated = useRef(false);
  const [busy, setBusy] = useState(false);
  const [nickname, setNickname] = useState("");
  const [userInfo, setUserInfo] = useState<UserInfo>({} as UserInfo);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [error, setError] = useState<unknown>();
  // const [newMessage, setNewMessage] = useState<ChatMessage>();
  const [search, setSearch] = useState<string>(initialSearch || "");
  const [searchText, setSearchText] = useState<string>("");
  const [token, setToken] = useState<string | null>(null);
  const [undoRemoveMessages, setUndoRemoveMessages] = useState<string[]>([]);
  // const [lastRemoveMessageTimestamp, setLastRemoveMessageTimestamp] = useState<number>(0);
  const [canUndoRemovedMessages, setCanUndoRemovedMessages] = useState(false);
  const info = useObserveProject(nickname);
  const authService = useRef(new AuthService());
  const undoChecker = useRef<NodeJS.Timeout>();
  const [hasNewMessages, setHasNewMessages] = useState(false);
  const [isOffline, setIsOffline] = useState(false);
  const lastKnownTimestampRef = useRef<Date>();
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [suggestionsSet, setSuggestionsSet] = useState(false);
  const [chatProjects, setChatProjects] = useState<UserInfo[]>([]);
  const [currentProject, setCurrentProject] = useState<string>();
  const [checkForNewMessages, setCheckForNewMessages] = useState(false);
  const project = useObserveProject(currentProject || nickname);
  const isQuerySearch = search.trim().length > 0 && search.trim().startsWith("?");
  const [bookmarks, setBookmarks] = useState<string[]>(getBookmarks());
  const [bookmarkedMessages, setBookmarkedMessages] = useState<ChatMessage[]>([]);
  const [log, setLog] = useState<string>();
  const abortControllerRef = useRef<AbortController | null>(null);

  // **New Refs for Polling Management**
  const isFetchingRef = useRef(false); // To track if a fetch is in progress
  const isMountedRef = useRef(true); // To track if the component is still mounted
  const pollingTimeoutRef = useRef<number | null>(null); // To manage the polling timeout
  const [pausePolling, setPausePolling] = useState(false); // To manage pausePolling state

  // Persist message to localStorage
  useEffect(() => {
    setSearch(sessionStorage.getItem("search" + (log || "")) || "");
    isUpdated.current = false;
  }, [log]);

  useEffect(() => {
    if (search.trim().length > 0) {
      sessionStorage.setItem("search" + (log || ""), search);
    } else {
      sessionStorage.removeItem("search" + (log || ""));
    }
  }, [search, log]);

  const getQuerySearch = () => {
    if (!isQuerySearch) return null;
    // use regex to extract project and query from search
    // example:
    // ?project1 where is London located?
    // result: project = project1, query = where is London located?
    // ? project2 where is Paris located?
    // result: project = project2, query = where is Paris located?
    const regex = /(\?)(\s*)(\w+)(\s*)(.*)/;
    const match = search.trim().match(regex);
    if (!match) return null;
    const project = match[3];
    const query = match[5];
    return { project, query };
  };

  const checkOnlineStatus = useCallback(() => {
    if (!navigator.onLine) {
      setIsOffline(true);
      return false;
    } else {
      setIsOffline(false);
      return true;
    }
  }, []);

  const getMessages = useCallback(() => {
    if (!handleMessages) return;
    if (!isAuthenticated) return;
    if (!checkOnlineStatus()) {
      return;
    }

    setBusy(true);
    isUpdated.current = true;
    const qs = getQuerySearch();
    if (qs) {
      const { project, query } = qs;
      Api.queryChatMessages(0, 10, project, query)
        .then((m) => {
          setMessages(m);
          setBusy(false);
        })
        .catch((err) => {
          console.log(err);
          if (err.message === "Unauthorized") {
            setIsAuthenticated(false);
          }
          setError(err);
          setBusy(false);
        });
    } else {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      abortControllerRef.current = new AbortController();

      Api.getChatMessages(
        0,
        maxMessagesPerApiCall,
        log ? "@" + log + " " + search : search,
        undefined,
        true,
        abortControllerRef.current.signal
      )
        .then((m) => {
          m = filterHiddenMessages(m, search);
          setMessages(m);
          if (m.length > 0) {
            lastKnownTimestampRef.current = new Date(m[0].createdAt.getTime() - 1000);
          }
          setBusy(false);
        })
        .catch((err) => {
          if (err.name === "AbortError") {
            // This error was caused by a cancelled request
            return;
          }

          console.log(err);
          if (err.message === "Unauthorized") {
            setIsAuthenticated(false);
          }

          setError(err);
          setBusy(false);
        });
    }
  }, [search, log, checkOnlineStatus, handleMessages, isAuthenticated]);

  const removeMessage = (id: string) => {
    const oldRemovedMessageToKeep = canUndoRemovedMessages ? undoRemoveMessages : [];
    hideMessage(id);
    setMessages(messages.filter((m) => m.id !== id));
    setUndoRemoveMessages([id, ...oldRemovedMessageToKeep]);
    setCanUndoRemovedMessages(true);
    if (undoChecker.current) {
      clearTimeout(undoChecker.current);
    }
    undoChecker.current = setTimeout(() => setCanUndoRemovedMessages(false), 9000);
  };

  const undoRemovedMessages = () => {
    undoRemoveMessages.forEach((id) => undoHideMessage(id));
    setUndoRemoveMessages([]);
    setCanUndoRemovedMessages(false);
    getMessages();
  };

  useEffect(() => {
    if (!canUndoRemovedMessages) {
      undoRemoveMessages.forEach((id) => Api.hideMessage(id));
    }
  }, [canUndoRemovedMessages]);

  const queueMessage = (message: ChatMessage) => {
    // var queuedMessages = JSON.parse(localStorage.getItem("queuedMessages") || "[]");
    // var messageJson = JSON.stringify(message);
    // const exists = queuedMessages.find((m: ChatMessage) => JSON.stringify(m) === messageJson);
    // if (!exists) {
    //   queuedMessages.push(message);
    // }
    // localStorage.setItem("queuedMessages", JSON.stringify(queuedMessages));
  };

  const refreshUserInfo = useCallback(async () => {
    Api.getUserInfo(userInfo.nickname, true)
      .then((userInfo) => {
        setUserInfo(userInfo);
      })
      .catch((err) => console.log(err));
  }, [userInfo.nickname]);

  // Adds any new messages to the existing (cached)
  const refreshMessages = useCallback(async () => {
    // if (messages.length > 0) {
    //   setBusy(true);
    //   try {
    //     const lastTimestamp = messages[0].createdAt;
    //     const newMessages = await Api.getChatMessages(0, maxMessagesPerApiCall, search, lastTimestamp);
    //     setMessages(mergeMessages(filterHiddenMessages(newMessages), messages));
    //   } catch (err) {
    //     console.warn(err);
    //   } finally {
    //     setBusy(false);
    //   }
    // } else {
    setBusy(true);
    setHasNewMessages(false);
    try {
      getMessages();
    } finally {
      setBusy(false);
    }
    // }
  }, [getMessages]);

  const sendMessage = useCallback(
    async (text: string, parentId?: string, type: Type = "info", properties?: any, token?: string) => {
      const tagIndex = text.indexOf("#");
      if (tagIndex !== -1 && !type) {
        const stopIndex = text.substring(tagIndex).indexOf(" ");
        type = (text.substring(tagIndex + 1, stopIndex === -1 ? undefined : stopIndex) as Type) || "info";
      }

      // use regex /#at \(([\w\W]+?)\)/ to test if it is a schedule message
      const scheduleRegex = /\s*#at \(([\w\W]+?)\)/;
      const scheduleMatch = text.match(scheduleRegex);
      if (scheduleMatch) {
        let date = Date.parse(scheduleMatch[1]);
        if (isNaN(date)) {
          const withDate = new Date(Date.parse(new Date().toDateString() + " " + scheduleMatch[1])).toISOString();
          date = Date.parse(withDate);
        }
        if (!isNaN(date)) {
          const message = text.replace(scheduleRegex, "");
          const ok = await Api.addScheduleItem(message, new Date(date));
          if (!ok) {
            console.warn("Failed to schedule message");
          }

          // TODO: text = text + " #hidden";
          type = "scheduled";
        } else {
          console.warn("Failed to parse date");
        }
      }

      const item: ChatMessage = {
        id: undefined,
        // userId: undefined,
        parentId,
        content: text,
        propertiesJson: properties ? JSON.stringify(properties) : undefined,
        type,
        createdAt: new Date(),
        nickname: nickname,
        isNew: false,
      };

      let id: string | undefined = undefined;

      if (!checkOnlineStatus()) {
        queueMessage(item);
        return id;
      }

      try {
        setBusy(true);
        try {
          id = await Api.addChatMessage(item, token);
        } catch (err) {
          console.warn(err);
          queueMessage(item);
        }

        await refreshMessages();
      } catch (err) {
        setError(err);
      } finally {
        setBusy(false);
      }

      return id;
    },
    [checkOnlineStatus, nickname, refreshMessages]
  );

  // **Polling useEffect with Recursive setTimeout**
  useEffect(() => {
    // Define the polling function
    const poll = async () => {
      if (!handleMessages || !isAuthenticated || isOffline || isFetchingRef.current || pausePolling) {
        scheduleNextPoll();
        return;
      }

      isFetchingRef.current = true;

      try {
        const newMessages = await Api.getChatMessages(
          0,
          1,
          log ? "@" + log + " " + search : search,
          lastKnownTimestampRef.current,
          true
        );

        setCheckForNewMessages(false);
        if (newMessages.length > 0) {
          lastKnownTimestampRef.current = new Date(newMessages[0].createdAt.getTime() - 1000);
          const hiddenMessages = getHiddenMessages();
          const hasMessage = messages[0]?.id === newMessages[0].id;
          const toOld = messages[0]?.createdAt.getTime() > newMessages[0].createdAt.getTime();
          const hasHiddenMessage =
            hiddenMessages.length > 0 && newMessages[0].id && hiddenMessages.includes(newMessages[0].id);
          if (!hasMessage && !hasHiddenMessage && !busy && !toOld && !hasNewMessages) {
            if (["ai-answer", "ai-prompt", "ai-chat-answer", "ai-chat-answer2"].includes(newMessages[0].type)) {
              await refreshMessages();
            } else {
              setHasNewMessages(true);
            }
          }
        }
      } catch (e: any) {
        console.warn(e);
        if (e.message === "Internal Server Error") {
          setPausePolling(true);
          pollingTimeoutRef.current = window.setTimeout(() => setPausePolling(false), 30000);
        }
      } finally {
        isFetchingRef.current = false;
        scheduleNextPoll();
      }
    };

    // Define the function to schedule the next poll
    const scheduleNextPoll = () => {
      if (!isMountedRef.current) return;
      pollingTimeoutRef.current = window.setTimeout(poll, 10000); // 10-second delay
    };

    // Start the initial poll
    if (handleMessages) {
      scheduleNextPoll();
    }

    // Cleanup function to clear any pending timeouts
    return () => {
      if (pollingTimeoutRef.current !== null) {
        clearTimeout(pollingTimeoutRef.current);
      }
    };
  }, [
    handleMessages,
    isAuthenticated,
    isOffline,
    pausePolling,
    log,
    search,
    messages,
    busy,
    hasNewMessages,
    refreshMessages,
    checkOnlineStatus,
  ]);

  const sendQueuedMessages = () => {
    return;
    // if (!checkOnlineStatus()) return;
    // var queuedMessages = JSON.parse(localStorage.getItem("queuedMessages") || "[]");
    // localStorage.setItem("queuedMessages", "[]");
    // queuedMessages.forEach((message: ChatMessage) => {
    //    const fileId = findFileId(message.content);
    //    if (fileId) {
    //       if (FileHelpers.isLocalFile(fileId)) {
    //          FileHelpers.uploadSavedFile(fileId)
    //             .then((newFileId) => {
    //                message.content = message.content.replace(`#file:${fileId}`, `#file:${newFileId}`);
    //                sendMessage(message.content, message.parentId, message.type);
    //             })
    //             .catch((err) => {
    //                console.log(err);
    //                queueMessage(message);
    //             });
    //       } else {
    //          sendMessage(message.content, message.parentId, message.type);
    //       }
    //    } else {
    //       sendMessage(message.content, message.parentId, message.type);
    //    }
    // });
  };

  // **Additional Polling useEffect to save messages**
  useEffect(() => {
    saveMessages(messages);
  }, [messages]);

  // Add incoming (new) message to existing
  // useEffect(() => {
  //   if (newMessage) {
  //     setNewMessage(undefined);
  //     setMessages([newMessage, ...messages]);
  //   }
  // }, [newMessage, messages]);

  const filterHiddenMessages = (messages: ChatMessage[], id: string) => {
    const hiddenMessages = getHiddenMessages();
    messages = messages.filter((msg) => !hiddenMessages.includes(msg.id!) || id === msg.id!);
    // TODO: move to messages list => messages = messages.filter((msg) => !msg.content.includes("#sveiserapport"));
    return messages;
  };

  // const filterChildren = (messages: ChatMessage[]) => {
  //    const parentIds = messages
  //       .filter(
  //          (m) =>
  //             m.parentId &&
  //             m.type !== "ai-chat-answer" &&
  //             m.type !== "ai-chat-prompt" &&
  //             m.type !== "ai-answer" &&
  //             m.type !== "ai-prompt" &&
  //             m.type !== "url"
  //       )
  //       .map((m) => m.parentId);
  //    return messages.filter((m) => parentIds.indexOf(m.id) === -1);
  // };

  const getVariable = (name: string) => {
    const { properties } = info;
    if (properties) {
      const variable = properties.find((v) => v.name === name);
      if (variable) {
        return variable.value;
      }
    }

    return null;
  };

  // const setVariable = (name: string, value: string) => {
  //    const { properties } = info;
  //    if (properties) {
  //       const variable = properties.find((v) => v.name === name);
  //       if (variable) {
  //          variable.value = value;
  //       } else {
  //          properties.push({ name, value } as Property);
  //       }
  //    }
  // };

  useEffect(() => {
    if (!suggestionsSet) {
      let mounted = true;
      if (handleMessages) {
        setSuggestionsSet(true);
        Api.getSuggestions()
          .then((s) => {
            if (mounted) {
              setSuggestions(s);
            }
          })
          .catch((err) => {
            console.warn(err);
            setTimeout(() => setSuggestionsSet(false), 10000);
          });
      }
      return () => {
        mounted = false;
      };
    }
  }, [suggestionsSet, handleMessages]);

  useEffect(() => {
    let mounted = true;
    if (isAuthenticated) {
      const userInfo = authService.current.getUserInfo();
      setUserInfo(userInfo);
      // getMessages();
      Api.getUserInfo(userInfo.nickname, true)
        .then((userInfo) => {
          if (mounted) setUserInfo(userInfo);
        })
        .catch((err) => console.warn(err));

      Api.getChatProjects()
        .then((projects) => {
          if (mounted) setChatProjects(projects);
        })
        .catch((err) => console.warn(err));
    }

    return () => {
      mounted = false;
    };
  }, [isAuthenticated]);

  useEffect(() => {
    if (userInfo) {
      setNickname(userInfo.nickname);
    }
  }, [userInfo]);

  useEffect(() => {
    if (authService.current) {
      setIsAuthenticated(authService.current.isAuthenticated());
      setToken(authService.current.getToken());
    }
  }, [authService]);

  useEffect(() => {
    if (!isUpdated.current) {
      getMessages();
    }
  }, [messages, getMessages]);

  useEffect(() => {
    const oldSearch = sessionStorage.getItem("old_search");
    const oldLog = sessionStorage.getItem("old_log") || undefined;
    if (oldSearch !== search || oldLog !== log) {
      sessionStorage.setItem("old_search", search);
      if (log) {
        sessionStorage.setItem("old_log", log);
      }
      getMessages();
    }
  }, [search, getMessages, log]);

  const [messagesToShow, setMessagesToShow] = useState<ChatMessage[]>([]);

  useEffect(() => {
    const sortedMessages = [...bookmarkedMessages, ...messages];
    setMessagesToShow(sortedMessages);
  }, [messages, bookmarkedMessages, searchText, search]);

  useEffect(() => {
    if (bookmarks.length > 0) {
      Api.getMessagesContent(bookmarks)
        .then(setBookmarkedMessages)
        .catch((err) => console.warn(err));
    }
  }, [bookmarks]);

  useEffect(() => {
    if (info) {
      const theme = getVariable("CODICENT_THEME");
      if (theme) {
        localStorage.setItem("theme", theme);
      } else {
        localStorage.removeItem("theme");
      }
    }
  }, [info]);

  const toggleMessageBookmark = (id: string) => {
    toggleBookmark(id);
    setBookmarks(getBookmarks());
  };

  // **Cleanup on Unmount**
  useEffect(() => {
    return () => {
      isMountedRef.current = false;
      if (pollingTimeoutRef.current !== null) {
        clearTimeout(pollingTimeoutRef.current);
      }
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      if (undoChecker.current) {
        clearTimeout(undoChecker.current);
      }
    };
  }, []);

  return {
    messages: messagesToShow,
    nickname,
    busy,
    isAuthenticated,
    authService: authService.current,
    sendMessage,
    error,
    refreshMessages,
    setSearch,
    getMessages,
    token,
    userInfo,
    search,
    info,
    removeMessage,
    canUndoRemovedMessages,
    undoRemovedMessages,
    hasNewMessages,
    getVariable,
    isOffline,
    refreshUserInfo,
    setSearchText,
    suggestions,
    chatProjects,
    project,
    setCurrentProject,
    checkForNewMessages: () => setCheckForNewMessages(true),
    toggleMessageBookmark,
    setLog,
    log,
  };
};
