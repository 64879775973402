import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./locales/en.json";
import sv from "./locales/sv.json";
import de from "./locales/de.json";
import nl from "./locales/nl.json";
import fr from "./locales/fr.json";
import es from "./locales/es.json";

i18n.use(initReactI18next).init({
   lng: "en",
   fallbackLng: "en",
   interpolation: {
      escapeValue: false,
   },
   resources: {
      en: {
         translation: en,
      },
      sv: {
         translation: sv,
      },
      de: {
         translation: de,
      },
      nl: {
         translation: nl,
      },
      fr: {
         translation: fr,
      },
      es: {
         translation: es,
      },
   },
});

export default i18n;
