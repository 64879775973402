import React, { useState } from "react";
import { ActionButton, FontIcon, mergeStyleSets, Stack, TextField } from "office-ui-fabric-react";
import { useHistory } from "react-router-dom";
import { useTheme } from "./useTheme";
import { useTranslation } from "react-i18next";

const theme = useTheme();
const styles = mergeStyleSets({
  bottomRightFloat: {
    position: "absolute",
    textAlign: "center",
    zIndex: 100,
    right: 32,
    bottom: 42,
  },
  bottomCenterFloat: {
    position: "absolute",
    textAlign: "center",
    zIndex: 100,
    left: "50%",
    bottom: 0,
    // marginLeft: "-36px",
    transform: "translateX(-50%)",
  },
  messageButton: {
    fontSize: 48,
    color: theme.semanticColors.primaryButtonBackground,
    textShadow: "2px 2px 6px #B0B0B0",
    cursor: "pointer",
    padding: 12,
    selectors: {
      ":hover": {
        textShadow: "2px 2px 6px #909090",
      },
    },
  },
  actionButton: {
    width: 210,
  },
  actionIcon: {
    fontSize: 24,
  },
});

export const NewMessageButton = ({
  text,
  onClick,
  onLongPress,
  style,
  id,
}: {
  text?: string;
  onClick?: () => void;
  onLongPress: () => void;
  style?: React.CSSProperties;
  id?: string;
}) => {
  const [pressTimer, setPressTimer] = useState<NodeJS.Timeout>();
  const history = useHistory();
  const { t } = useTranslation();

  const _onClick = () => {
    const url = text ? `/compose?text=${text}` : "/compose";
    history.push(url);
  };

  const startPress = () => {
    let timer = setTimeout(onLongPress, 600);
    setPressTimer(timer);
  };

  const cancelPress = () => {
    if (pressTimer) clearTimeout(pressTimer);
    setPressTimer(undefined);
  };

  return (
    <FontIcon
      id={id}
      iconName="CircleAdditionSolid"
      className={styles.messageButton}
      onClick={onClick || _onClick}
      onMouseDown={startPress}
      onMouseUp={cancelPress}
      onMouseLeave={cancelPress}
      onTouchStart={startPress}
      onTouchEnd={cancelPress}
      title={t("New message")}
      style={style}
    />
  );
};

export const FooterButton = ({
  iconName,
  onClick,
  onLongPress,
  title,
  style,
  id,
}: {
  text?: string;
  iconName: string;
  onClick?: () => void;
  onLongPress?: () => void;
  title?: string;
  style?: React.CSSProperties;
  id?: string;
}) => {
  const [pressTimer, setPressTimer] = useState<NodeJS.Timeout>();

  const startPress = () => {
    if (onLongPress) {
      let timer = setTimeout(onLongPress, 600);
      setPressTimer(timer);
    }
  };

  const cancelPress = () => {
    if (pressTimer) clearTimeout(pressTimer);
    setPressTimer(undefined);
  };

  return (
    <FontIcon
      id={id}
      iconName={iconName}
      style={style}
      className={styles.messageButton}
      onClick={onClick}
      onMouseDown={startPress}
      onMouseUp={cancelPress}
      onMouseLeave={cancelPress}
      onTouchStart={startPress}
      onTouchEnd={cancelPress}
      title={title}
    />
  );
};

export const BottomCenterFloat: React.FunctionComponent = ({ children }) => (
  <Stack horizontal className={styles.bottomCenterFloat}>
    {children}
  </Stack>
);

export class Header extends React.Component<{ color?: string; textColor?: string; id?: string }> {
  render() {
    const { color, textColor, id } = this.props;
    return (
      <div
        id={id || "header"}
        style={{
          backgroundColor: color ? color : theme.palette.themePrimary,
          color: textColor || "white",
          position: "absolute",
          top: "0px",
          left: "0px",
          height: "40px",
          right: "0px",
          overflow: "hidden",
          zIndex: 1,
        }}
      >
        {this.props.children}
      </div>
    );
  }
}

export const Page = (props: { children: any; id?: string }) => (
  <div
    id={props.id || "page-page"}
    style={{
      position: "absolute",
      top: "0px",
      bottom: "1px", // "40px",  // TODO: why is 1px needed to make the new message button stick to the bottom?
      left: "0px",
      right: "0px",
      overflow: "auto",
    }}
  >
    {props.children}
  </div>
);

export class Content extends React.Component<{ backgroundColor?: string; id?: string }> {
  render() {
    return (
      <div
        id={this.props.id || "content"}
        style={{
          position: "absolute",
          top: "40px",
          bottom: "1px", // "40px",  // TODO: why is 1px needed to make the new message button stick to the bottom?
          left: "0px",
          right: "0px",
          overflow: "auto",
          backgroundColor: this.props.backgroundColor || theme.semanticColors.bodyBackground,
        }}
      >
        {this.props.children}
      </div>
    );
  }
}

export class Centered extends React.Component<{ backgroundColor?: string }> {
  render() {
    return (
      <div
        id="content"
        style={{
          overflow: "auto",
          backgroundColor: this.props.backgroundColor || theme.semanticColors.bodyBackground,
        }}
      >
        {this.props.children}
      </div>
    );
  }
}

export class Footer extends React.Component<{ color?: string }> {
  render() {
    const { color } = this.props;
    return (
      <div
        id="footer"
        style={{
          backgroundColor: color || "#0078d4",
          color: "white",
          position: "absolute",
          bottom: "0px",
          height: "40px",
          left: "0px",
          right: "0px",
          overflow: "hidden",
          zIndex: 1,
        }}
      >
        {this.props.children}
      </div>
    );
  }
}

export const Bottom = (props: any) => {
  return (
    <div
      // id="bottom"
      style={{
        // backgroundColor: "#0078d4",
        // color: "white",
        position: "absolute",
        bottom: "8px",
        height: "40px",
        left: "0px",
        right: "8px",
        overflow: "hidden",
      }}
    >
      {props.children}
    </div>
  );
};

export const Task: React.FunctionComponent = ({ children }) => (
  <div style={{ marginBottom: "0.5rem", color: theme.palette.themePrimary }}>&#62; {children}</div>
);

export const CloseButton = ({ onClose }: { onClose: () => void }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const _close = () => {
    if (document.referrer === "") {
      history.replace("/");
    } else {
      history.goBack();
    }
    onClose();
  };

  const canGoBack = history.length > 1;
  if (!canGoBack) return null;

  return (
    <ActionButton title={t("Close")} onClick={_close} iconProps={{ iconName: "Cancel" }}>
      Close
    </ActionButton>
  );
};

export const WandIcon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M37.75 6C38.4403 6 39 6.55964 39 7.25V9H40.75C41.4403 9 42 9.55964 42 10.25C42 10.9404 41.4403 11.5 40.75 11.5H39V13.25C39 13.9404 38.4403 14.5 37.75 14.5C37.0596 14.5 36.5 13.9404 36.5 13.25V11.5H34.75C34.0596 11.5 33.5 10.9404 33.5 10.25C33.5 9.55964 34.0596 9 34.75 9H36.5V7.25C36.5 6.55964 37.0596 6 37.75 6ZM15 11.25C15 10.5596 14.4403 10 13.75 10C13.0596 10 12.5 10.5596 12.5 11.25V13H10.75C10.0596 13 9.49999 13.5596 9.49999 14.25C9.49999 14.9404 10.0596 15.5 10.75 15.5H12.5V17.25C12.5 17.9404 13.0596 18.5 13.75 18.5C14.4403 18.5 15 17.9404 15 17.25V15.5H16.75C17.4403 15.5 18 14.9404 18 14.25C18 13.5596 17.4403 13 16.75 13H15V11.25ZM33.75 30C34.4403 30 35 30.5596 35 31.25V33H36.75C37.4403 33 38 33.5596 38 34.25C38 34.9404 37.4403 35.5 36.75 35.5H35V37.25C35 37.9404 34.4403 38.5 33.75 38.5C33.0596 38.5 32.5 37.9404 32.5 37.25V35.5H30.75C30.0596 35.5 29.5 34.9404 29.5 34.25C29.5 33.5596 30.0596 33 30.75 33H32.5V31.25C32.5 30.5596 33.0596 30 33.75 30ZM25.8895 15.4044C27.7445 13.5494 30.7521 13.5494 32.607 15.4044C34.462 17.2594 34.462 20.2669 32.607 22.1219L12.1132 42.6158C10.2582 44.4707 7.25069 44.4707 5.3957 42.6158C3.54071 40.7608 3.54071 37.7532 5.3957 35.8982L25.8895 15.4044ZM24.5309 20.2986L7.16347 37.666C6.28479 38.5447 6.28479 39.9693 7.16347 40.848C8.04215 41.7267 9.46677 41.7267 10.3454 40.848L27.7129 23.4806L24.5309 20.2986ZM29.4806 21.7128L30.8393 20.3542C31.718 19.4755 31.718 18.0509 30.8393 17.1722C29.9606 16.2935 28.536 16.2935 27.6573 17.1722L26.2987 18.5308L29.4806 21.7128Z"
      fill={theme.palette.themePrimary}
    />
  </svg>
);

export const TextField2 = ({
  label,
  placeholder,
  value,
  onChange,
  type,
}: {
  label: string;
  placeholder: string;
  value: string | null;
  onChange: (value: string) => void;
  type?: string;
}) => {
  const [text, setText] = useState(value || "");
  return (
    <TextField
      autoComplete="off"
      label={label}
      placeholder={placeholder}
      value={text}
      onChange={(_e, newValue) => setText(newValue || "")}
      onBlur={(e) => onChange(e.target.value || "")}
      type={type}
    />
  );
};

export const AButton = (props: {
  id?: string;
  iconName: string;
  href?: string;
  children: any;
  onClick?: () => void;
}) => (
  <ActionButton
    id={props.id}
    className={styles.actionButton}
    iconProps={{ styles: { root: { fontSize: 28 } }, iconName: props.iconName }}
    href={props.href}
    onClick={props.onClick}
  >
    {props.children}
  </ActionButton>
);
