import { useEffect, useState } from "react";
import Api from "./api";
import { useTheme } from "./useTheme";

const theme = useTheme();

interface AppTheme {
  color: string;
  textColor: string;
  backgroundcolor: string;
  title?: string;
  logo?: string;
  themeColor: string;
  buttonColor: string;
  buttonTextColor: string;
  font?: string;
  welcome?: string;
  chatPlaceholder?: string;
  textPlaceholder?: string;
  transcriptionInstructions?: string;
}

export const useObserveAppTheme = (project: string) => {
  const savedTheme = localStorage.getItem(`${project}_theme`);
  const [appTheme, setAppTheme] = useState<AppTheme>(
    savedTheme
      ? JSON.parse(savedTheme)
      : ({
          color: theme.semanticColors.menuBackground,
          textColor: theme.semanticColors.bodyText,
          backgroundcolor: theme.semanticColors.bodyBackground,
          title: undefined,
          themeColor: theme.semanticColors.menuBackground,
          buttonColor: theme.semanticColors.bodyBackground,
          buttonTextColor: "white",
        } as AppTheme)
  );
  const [buttonStyles, setButtonStyles] = useState({});

  useEffect(() => {
    if (project) {
      Api.getUserInfo(project)
        .then((info) => {
          if (info) {
            // const backgroundColor = info.properties.find((p) => p.name === "CODICENT_CHAT_BACKGROUND_COLOR");
            // const color = info.properties.find((p) => p.name === "CODICENT_CHAT_COLOR");
            // const textColor = info.properties.find((p) => p.name === "CODICENT_CHAT_TEXT_COLOR");
            // const title = info.properties.find((p) => p.name === "CODICENT_CHAT_TITLE");
            let theme = appTheme;
            // if (backgroundColor) theme.color = backgroundColor.value;
            // if (color) theme.backgroundcolor = color.value;
            // if (textColor) theme.textColor = textColor.value;
            // if (title) theme.title = title.value;
            Api.findDataMessages(project, ["app-theme"])
              .then((data) => {
                if (data.length > 0) {
                  theme = { ...theme, ...data[0] };
                }
              })
              .catch(console.log)
              .finally(() => {
                setAppTheme(theme);
                localStorage.setItem(`${project}_theme`, JSON.stringify(theme));
                const { backgroundcolor, color } = theme;
                const colors = {
                  backgroundColor: backgroundcolor,
                  borderColor: backgroundcolor,
                  color: color,
                };
                setButtonStyles({
                  root: {
                    // margin: 4,
                    // fontSize: "medium",
                    ...colors,
                    selectors: {
                      ":active": {
                        ...colors,
                      },
                    },
                  },
                  rootDisabled: {
                    ...colors,
                    selectors: {
                      ":active": {
                        ...colors,
                      },
                    },
                  },
                  rootHovered: {
                    ...colors,
                  },
                });
              });
          }
        })
        .catch(console.log);
    }
  }, [project]);

  return { appTheme, buttonStyles };
};
