import React from "react";
import { Persona, PersonaSize } from "office-ui-fabric-react";
import Api from "../api";
import { useObserveProject } from "../useObserveProject";
import { useTheme } from "../useTheme";

export const ProjectPersona = ({ project }: { project: string }) => {
  const { picture, description } = useObserveProject(project);
  const theme = useTheme();
  return (
    <Persona
      imageUrl={picture ? Api.imageUrl(picture, 48) : undefined}
      text={"@" + project}
      secondaryText={description}
      size={PersonaSize.size48}
      hidePersonaDetails={false}
      // presence={PersonaPresence.online}
      imageAlt={project}
      styles={{ primaryText: { color: theme.palette.themePrimary } }}
    />
  );
};
