import React, { useEffect, useState } from "react";
import {
  Stack,
  PrimaryButton,
  MessageBar,
  MessageBarType,
  Label,
  mergeStyleSets,
  Persona,
  PersonaSize,
} from "office-ui-fabric-react";
import { StackVert, PageTitle, ParagraphText } from "./Controls";
import Api from "../api";
import { NicknameTextField } from "./NicknameTextField";
import { t } from "i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { Content, Header } from "../components";
import { useTheme } from "../useTheme";

const theme = useTheme();
const styles = mergeStyleSets({
  headerTitle: {
    color: theme.semanticColors.primaryButtonText,
    fontSize: 20,
    userSelect: "none",
    cursor: "pointer",
  },
  persona: {
    margin: 8,
    marginLeft: 10,
    marginRight: 10,
    cursor: "pointer",
  },
  content: {
    marginLeft: 8,
    marginRight: 8,
  },
});

interface RegisterAuth0UserProps {
  onRegister: (token: string) => void;
}

export const RegisterAuth0User = (props: RegisterAuth0UserProps) => {
  const [name, setName] = useState("");
  const [message, setMessage] = useState<string>();
  const [error, setError] = useState<string>();
  const [busy, setBusy] = useState(false);
  const [nameValid, setNameValid] = useState(false);
  const { getAccessTokenSilently, user } = useAuth0();

  const [color, setColor] = useState<string | undefined>(localStorage.getItem("CODICENT_CHAT_COLOR") || undefined);
  const [backgroundColor, setBackgroundColor] = useState<string | undefined>(
    localStorage.getItem("CODICENT_CHAT_BACKGROUND_COLOR") || undefined
  );
  const [textColor, setTextColor] = useState<string | undefined>(
    localStorage.getItem("CODICENT_CHAT_TEXT_COLOR") || undefined
  );

  useEffect(() => setNameValid(!!name.match(/^[a-zA-Z0-9-_]+$/g)), [name]);

  const register = async () => {
    try {
      setBusy(true);
      const accessToken = await getAccessTokenSilently({ audience: "https://codicent.com/api" });
      const token = await Api.registerAuth0User(name, user.email, user.sub, accessToken);
      if (token === "") {
        setMessage(t("Register error! Please try again!"));
        setBusy(false);
      }

      props.onRegister(token);
    } catch {
      setError(t("Network error! Please try again!"));
      setBusy(false);
    }
  };

  return (
    <StackVert>
      <Header color={color} textColor={backgroundColor}>
        <Stack horizontal>
          <Persona
            id="home_edit_user"
            imageUrl={"/logo192.png"}
            size={PersonaSize.size24}
            hidePersonaDetails={true}
            imageAlt={t("Home")}
            className={styles.persona}
            title={t("Home")}
          />
          <Stack.Item align="center" grow disableShrink>
            <Label className={styles.headerTitle}>{"Codicent"}</Label>
          </Stack.Item>
        </Stack>
      </Header>
      <Content>
        <Stack className={styles.content} tokens={{ childrenGap: 15 }}>
          <Stack.Item>
            <PageTitle>{t("Register")}</PageTitle>
            <ParagraphText>{t("Register an account for free.")}</ParagraphText>
          </Stack.Item>
          <Stack.Item>
            <NicknameTextField name={name} onChange={setName} />
          </Stack.Item>
          <Stack.Item>
            <ParagraphText>
              {t("Clicking Register also agrees to the")}{" "}
              <a href="/privacy" target="_blank">
                {t("privacy policy")}
              </a>
              .
            </ParagraphText>
          </Stack.Item>
          <Stack.Item>
            {error && <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>}
            {message && <MessageBar messageBarType={MessageBarType.info}>{message}</MessageBar>}
          </Stack.Item>
          <Stack.Item>
            <PrimaryButton disabled={name.trim().length === 0 || busy || !nameValid} onClick={register}>
              {t("Register")}
            </PrimaryButton>
          </Stack.Item>
        </Stack>
      </Content>
    </StackVert>
  );
};
