import React, { useState } from "react";
import { mergeStyleSets, ContextualMenu, IconButton, IButtonStyles, Stack, Label } from "office-ui-fabric-react";

const Header = ({
  color,
  textColor,
  title,
  onTitleClick,
  menuItems,
  logo,
  onBackClick,
}: {
  color?: string;
  textColor?: string;
  id: string;
  title?: string;
  onTitleClick?: () => void;
  menuItems: { key: string; text: string; onClick: () => void }[];
  logo?: string;
  onBackClick?: () => void;
}) => {
  const styles = mergeStyleSets({
    header: {
      backgroundColor: color,
      color: textColor,
      padding: "8px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      position: "relative",
      zIndex: 1000,
      height: "2.5rem",
    },
    backButton: {
      marginRight: "8px",
      color: textColor,
    },
    logo: {
      height: "2.5rem",
      marginRight: "8px",
      cursor: "pointer",
      objectFit: "contain",
    },
    title: {
      color: textColor,
      fontSize: 24,
      cursor: "pointer",
    },
    menuContainer: {
      position: "relative",
    },
    menuButton: {
      margin: 0,
      padding: 0,
      background: "none",
      border: "none",
      cursor: "pointer",
    },
    icon: {
      fontSize: "18px",
    },
  });

  const buttonStyles: IButtonStyles = {
    root: {
      margin: 4,
      fontSize: "medium",
      backgroundColor: color,
      borderColor: color,
      color: textColor,
      selectors: {
        ":active": {
          backgroundColor: color,
          borderColor: color,
          color: textColor,
        },
      },
    },
    rootDisabled: {
      backgroundColor: color,
      borderColor: color,
      color: textColor,
      selectors: {
        ":active": {
          backgroundColor: color,
          borderColor: color,
          color: textColor,
        },
      },
    },
    rootHovered: {
      backgroundColor: color,
      borderColor: color,
      color: textColor,
    },
  };

  const [isContextMenuVisible, setIsContextMenuVisible] = useState(false);
  const [target, setTarget] = useState(null);

  const openContextMenu = (event: any) => {
    setIsContextMenuVisible(true);
    setTarget(event.target);
  };

  const closeContextMenu = () => {
    setIsContextMenuVisible(false);
  };

  return (
    <header className={styles.header}>
      <Stack horizontal styles={{ root: { width: "100%" } }}>
        {onBackClick && (
          <IconButton
            iconProps={{ iconName: "Back" }}
            onClick={onBackClick}
            ariaLabel="Back"
            className={styles.backButton}
            styles={buttonStyles}
          />
        )}
        <Stack.Item align="start" grow disableShrink>
          {logo && <img className={styles.logo} src={logo} alt={"Logo"} onClick={onTitleClick} />}
          {title && (
            <Label onClick={onTitleClick} className={styles.title}>
              {title}
            </Label>
          )}
        </Stack.Item>
        {menuItems.length > 0 && (
          <Stack.Item align="center">
            <IconButton
              iconProps={{
                iconName: "MoreVertical",
                style: { fontSize: 20, marginRight: 6, color: textColor || "white" },
              }}
              className={styles.icon}
              styles={buttonStyles}
              onClick={openContextMenu}
            >
              <svg fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7"></path>
              </svg>
            </IconButton>
            {isContextMenuVisible && (
              <ContextualMenu items={menuItems} target={target} onDismiss={closeContextMenu} gapSpace={10} />
            )}
          </Stack.Item>
        )}
      </Stack>
    </header>
  );
};

export default Header;
