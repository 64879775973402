import React from 'react';
import { PrimaryButton, DefaultButton, IButtonProps as _IButtonProps } from 'office-ui-fabric-react';

export type IButtonProps = _IButtonProps | { type?: 'default' | 'primary' };

export const Button = (props: IButtonProps) => {
    if (props.type === 'primary') {
        return <PrimaryButton {...props as IButtonProps} />;
    }
    
    return <DefaultButton {...props} />;
};

