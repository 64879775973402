import React, { useEffect, useState } from "react";
import { FocusZone, IRectangle, List, Stack } from "office-ui-fabric-react";
import { ProjectPersona } from "./ProjectPersona";
import { CodicentInfo } from "../models";
import Api from "../api";
import { ParagraphLabel, ParagraphText } from "./Controls";

interface CodicentGridProps {
   // projects: string[];
   // onProjectClick: (project?: string) => void;
   maxRowHeight?: number;
}

export const CodicentGrid = ({ /*projects, onProjectClick, */maxRowHeight }: CodicentGridProps) => {
   const ROWS_PER_PAGE = 2;
   const MAX_ROW_HEIGHT = maxRowHeight || 250;
   const columnCount = React.useRef(0);
   const rowHeight = React.useRef(0);
   const [sortedCodicents, setSortedCodicents] = useState<CodicentInfo[]>([]);
   const [codicents, setCodicents] = useState<CodicentInfo[]>([]);

   useEffect(() => {
      Api.getCodicents().then(setCodicents).catch(console.error);
   }, []);

   useEffect(() => {
      setSortedCodicents(codicents.sort((a, b) => a.data.Name.toLowerCase().localeCompare(b.data.Name.toLowerCase())));
   }, [codicents]);

   const getPageHeight = (): number => {
      return rowHeight.current * ROWS_PER_PAGE;
   };

   const getItemCountForPage = (itemIndex?: number, visibleRect?: IRectangle) => {
      if (itemIndex === 0 && visibleRect) {
         columnCount.current = Math.ceil(visibleRect.width / MAX_ROW_HEIGHT);
         rowHeight.current = Math.floor(visibleRect.width / columnCount.current);
      }
      return columnCount.current * ROWS_PER_PAGE;
   };

   return (
      <FocusZone>
         <List
            items={sortedCodicents}
            onRenderCell={(codicent?: CodicentInfo) => {
               if (!codicent || codicent.mentions.length === 0) return null;
               return (
                  <div
                     style={{
                        width: 95 / columnCount.current + "%",
                        // textAlign: "center",
                        outline: "none",
                        position: "relative",
                        float: "left",
                        margin: "4px",
                        padding: "4px",
                        border: "1px solid #ccc",
                        height: "180px",
                        overflow: "auto",
                     }}
                  // onClick={() => onProjectClick(codicent)}
                  >
                     <Stack>
                        <ProjectPersona project={codicent.mentions[0]} />
                        <ParagraphLabel>{codicent.data.Name}</ParagraphLabel>
                        <ParagraphText>{codicent.data.Description}</ParagraphText>
                        <Stack>
                           {Object.keys(codicent.data).filter(k => !["Name", "Description"].includes(k)).map((key: string) => <ParagraphText><pre>{codicent.data[key]}</pre></ParagraphText>)}
                        </Stack>
                     </Stack>
                  </div>
               );
               //  return <ProjectPersona project={project} onClick={() => onProjectClick(project)} />;
            }}
            getItemCountForPage={getItemCountForPage}
            getPageHeight={getPageHeight}
            renderedWindowsAhead={10}
         />
      </FocusZone>
   );
};
