import Api from "./api";
import { ChatMessage } from "./models";

export const getTextWithoutMentionsAndTags = (text: string) => {
  if (isData(text)) {
    return JSON.stringify(getData(text), null, 2);
  } else {
    return text
      .replace(/#file:.{36}/g, "")
      .replace(/(?:^|\s)@[a-zA-Z0-9_-]+(\s|$)/g, "")
      .replace(/(?:^|\s)#[a-zA-Z0-9_-]+/g, "");
  }
};

export const getMentionsAndTagsWithoutText = (text: string) => {
  const words = getContentWithoutData(text).split(/\s+|\n/);
  let mentions = words.filter((t) => t.startsWith("@"));
  let tags = words.filter((t) => t.startsWith("#"));
  return mentions.join(" ") + (mentions.length ? " " : "") + tags.join(" ") + (tags.length ? " " : "");
};

export const getMentions = (text: string) => {
  const matches = getContentWithoutData(text).match(/(?<=^|\s|\n)@[a-zA-Z0-9_-]+(?=[\s\n.,!?]|$)/g);
  if (!matches) {
    return [];
  }
  return matches.map((m) => m.trim());
};

export const hasMentions = (text: string) => getMentions(text).length > 0;

export const getTags = (text: string) => {
  if (!text) {
    return [];
  }

  const matches = getContentWithoutData(text).match(/(?<=^|\s|\n)#[a-zA-Z0-9_-]+(?=\s|$|\n)/g);
  if (!matches) {
    return [];
  }
  return matches.map((m) => m.trim());
};

export const hasTag = (text: string, tag: string) => getTags(text).some((t) => t === `#${tag}`);

export const hasMention = (text: string, mention: string) => getMentions(text).some((t) => t === `@${mention}`);

export const findFileId = (text: string) => {
  const pattern = /#file:[a-zA-Z0-9-]+/gi;
  var result = text.match(pattern);
  if (result && result.length > 0) {
    const file = result[0];
    if (file) {
      const fileId = file.substring(6);
      return fileId;
    }
  }
  return null;
};

export const findFileIds = (text: string) => {
  text = getContentWithoutData(text); // make sure we don't include data in the search
  const pattern = /#file:[a-zA-Z0-9-]+/gi;
  var result = text.match(pattern);
  if (result && result.length > 0) {
    return result.map((r) => r.substring(6));
  }

  return [];
};

export const getTextWithoutMentions = (text: string) =>
  text
    .split(/\s+|\n/)
    .filter((t) => !t.startsWith("@"))
    .join(" ");

export const saveTagMentionClick = (item: string) => {
  let clicks = JSON.parse(localStorage.getItem("tagMentionClicks") || "[]");
  if (clicks.length > 50) {
    clicks.shift();
  }
  clicks.push(item);
  localStorage.setItem("tagMentionClicks", JSON.stringify(clicks));
};

export const getTagMentionClicks = () => JSON.parse(localStorage.getItem("tagMentionClicks") || "[]") as string[];

export const share = (title: string, text: string, url: string) => {
  let shareNavigator: any = window.navigator;
  if (shareNavigator && shareNavigator.canShare()) {
    shareNavigator.share({ title, text });
  } else {
    window.open(
      `mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(text + "\n\n" + url)}`,
      "_blank"
    );
  }
};

export const extractTagsAndMentions = (text: string) => {
  let mentions = getMentions(text);
  let tags = getTags(text);
  return mentions.join(" ") + (mentions.length ? " " : "") + tags.join(" ") + (tags.length ? " " : "");
};

export const hideMessage = (id: string) => {
  let hiddenMessages = getHiddenMessages();
  if (hiddenMessages.length > 1000) {
    // TODO: change limit?
    hiddenMessages.shift();
  }
  hiddenMessages.push(id);
  localStorage.setItem("hiddenMessages", JSON.stringify(hiddenMessages));
};

export const getHiddenMessages = () => JSON.parse(localStorage.getItem("hiddenMessages") || "[]") as string[];

export const undoHideMessage = (id: string) => {
  let hiddenMessages = getHiddenMessages();
  hiddenMessages = hiddenMessages.filter((m) => m !== id);
  localStorage.setItem("hiddenMessages", JSON.stringify(hiddenMessages));
};

export const getTextWithoutFile = (text: string) => text.replace(/#file:.{36}/g, "");

export const showNotification = (message: string) => {
  if (Notification.permission === "granted") {
    navigator.serviceWorker.getRegistration().then((reg) => {
      if (reg) {
        reg.showNotification(message);
      }
    });
  }
};

export const isMarkdown = (text: string): boolean => {
  const codicentTags = [
    "#todo",
    "#issue",
    "#bug",
    "#lead",
    "#file",
    "#gps",
    "#transcribe",
    "#met",
    "#markdown",
    "#log",
  ];
  let cleanedText = text;
  for (const tag of codicentTags) {
    cleanedText = cleanedText.replace(new RegExp(tag, "g"), "");
  }

  const markdownPatterns = [
    "^#+\\s+\\w+",
    "\\*\\*[^*]+\\*\\*",
    "_[^_]+_",
    "\\[[^\\]]+\\]\\([^\\)]+\\)",
    "!\\[[^\\]]+\\]\\([^\\)]+\\)",
    "`[^`]+`",
    "^```[^`]+```$",
    "^-\\s+\\w+",
    "^\\d+\\.\\s+\\w+",
    "^>\\s+\\w+",
    "^\\|(\\s*:?-+:?\\s*\\|)+$",
    // Added patterns for math equations
    "\\$[^$]+\\$", // Inline math with $...$
    "\\\\\\([\\s\\S]+?\\\\\\)", // Inline math with \\(...\\)
    "\\$\\$[\\s\\S]+?\\$\\$", // Block math with $$...$$
    "\\\\\\[[\\s\\S]+?\\\\\\]", // Block math with \\[...\\]
  ];

  for (const pattern of markdownPatterns) {
    if (new RegExp(pattern, "gm").test(cleanedText)) {
      return true;
    }
  }

  return false;
};

export const speechApiKey = "09984ac64ca14779b753ca903b689738";

export const setMetaThemeColor = (color: string) => {
  let themeColor = document.querySelector("meta[name=theme-color]") as HTMLMetaElement;
  if (!themeColor) {
    themeColor = document.createElement("meta");
    themeColor.name = "theme-color";
    document.head.appendChild(themeColor);
  }
  themeColor.content = color;
};

export const replaceAmpInHref = (text: string) => {
  const hrefRegex = /(<a href=")([^"]*)(">)([^<]*)(<\/a>)/g;

  return text.replace(hrefRegex, (match, startTag, href, tagMiddle, linkText, endTag) => {
    // Replace '&amp;' with '&' in href only
    const newHref = href.replace(/&amp;/g, "&");
    return `${startTag}${newHref}${tagMiddle}${linkText}${endTag}`;
  });
};

export const getBaseUrl = () => {
  const protocolAndDomain = window.location.protocol + "//" + window.location.host;
  return protocolAndDomain;
};

export const arrayMatch = (array1: string[], array2: string[]) => {
  // Sort the arrays
  const sortedArray1 = array1.sort();
  const sortedArray2 = array2.sort();

  // Compare the arrays
  const isMatch =
    sortedArray1.length === sortedArray2.length && sortedArray1.every((value, index) => value === sortedArray2[index]);
  return isMatch;
};

export const contentIsJson = (content: string) => {
  try {
    return Object.keys(JSON.parse(getTextWithoutMentionsAndTags(content))).length > 0;
  } catch {
    return false;
  }
};

export const shortenContent = (content: string, maxLength: number = 80) => {
  if (!content) {
    return "";
  }

  content = getTextWithoutMentionsAndTags(content).trim();

  if (content.trim().startsWith("```html")) {
    let endOfHtml = content.trim().indexOf("```", 6);
    if (endOfHtml > 0) {
      let html = content.trim().substring(0, endOfHtml);
      return html;
    }
  }

  if (content.length <= maxLength) {
    return content;
  }

  return content.substring(0, maxLength) + "...";
};

const buildChatConversationText = (messages: ChatMessage[]) => {
  const conversationText = messages
    .filter((m) => !contentIsJson(m.content))
    .map((m) => {
      const isBotMessage =
        m.type === "ai-chat-answer" ||
        m.type === "ai-answer" ||
        m.type === "ai-answer2" ||
        m.type === "ai-chat-answer2";
      const title = isBotMessage ? "AI:" : "Human:";
      const content = getTextWithoutMentionsAndTags(m.content);
      return `${title}\n${m.createdAt.toISOString()}\n${content}`;
    })
    .join("\n\n");
  return conversationText;
};

export const getChatConversationAsText = async (conversationId: string) => {
  const messages = await Api.getMessageHistory(conversationId);
  return buildChatConversationText(messages);
};

export const getTimeString = (t: Date) => {
  const dt = new Date().getTime() - t.getTime();
  const seconds = dt / 1000;
  const minutes = seconds / 60;
  const hours = minutes / 60;
  const days = hours / 24;
  if (minutes < 1) {
    return "now";
  } else if (hours < 1) {
    return Math.round(minutes) + "m";
  } else if (days < 1) {
    return Math.round(hours) + "h";
  } else {
    return Math.round(days) + "d";
  }
};

export const formatDateAndTimeWithoutSeconds = (date: Date) => {
  return date.toLocaleString(undefined, {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const formatDate = (date: Date) => {
  return date.toLocaleString(undefined, {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
};

export const toggleBookmark = (id: string) => {
  let bookmarked = getBookmarks();
  if (!bookmarked.includes(id)) {
    bookmarked.push(id);
    localStorage.setItem("bookmarks", bookmarked.join(","));
  } else {
    bookmarked = bookmarked.filter((b) => b !== id);
    localStorage.setItem("bookmarks", bookmarked.join(","));
  }
};

// export const isBookmarked = (id: string) => {
//   let bookmarked = getBookmarks();
//   return bookmarked.includes(id);
// };

export const getBookmarks = () => {
  let bookmarks = localStorage.getItem("bookmarks");
  if (!bookmarks) bookmarks = "";
  let bookmarked = bookmarks.split(",").filter(Boolean);
  return bookmarked;
};

export const isData = (message?: string) => {
  if (!message) return false;
  const cleanMessage = message
    .replace(/#file:.{36}/g, "")
    .replace(/(?:^|\s)@[a-zA-Z0-9_-]+(\s|$)/g, "")
    .replace(/(?:^|\s)#[a-zA-Z0-9_-]+/g, "");
  if (!cleanMessage.trim().startsWith("{")) return false;
  const start = message.indexOf("{");
  const end = message.lastIndexOf("}");
  if (start !== -1 && end !== -1) {
    const data = message.substring(start, end + 1);
    try {
      JSON.parse(data);
      return true;
    } catch {
      return false;
    }
  }

  return false;
};

export const getContentWithoutData = (message: string) => {
  if (isData(message)) {
    const start = message.indexOf("{");
    const end = message.lastIndexOf("}");
    const content = message.substring(0, start) + message.substring(end + 1);
    return content;
  }

  return message;
};

export const getData = (message: string) => {
  if (isData(message)) {
    const start = message.indexOf("{");
    const end = message.lastIndexOf("}");
    const data = message.substring(start, end + 1);
    return JSON.parse(data);
  }

  return {};
};

export const getIconStyles = (color?: string, textColor?: string) => {
  if (!color || !textColor) return undefined;
  return {
    root: {
      margin: 4,
      fontSize: "medium",
      backgroundColor: color,
      borderColor: color,
      color: textColor,
      selectors: {
        ":active": {
          backgroundColor: color,
          borderColor: color,
          color: textColor,
        },
      },
    },
    rootDisabled: {
      backgroundColor: color,
      borderColor: color,
      color: textColor,
      selectors: {
        ":active": {
          backgroundColor: color,
          borderColor: color,
          color: textColor,
        },
      },
    },
    rootHovered: {
      backgroundColor: color,
      borderColor: color,
      color: textColor,
    },
  };
};

// content contains html wrapper (```html ... ```)
export const tryGetHtml = (text: string) => {
  const html = text.match(/```html\n([\s\S]+?)\n```/);
  if (html) {
    return html[1];
  }
  return null;
};

export const getTextWithoutHtml = (text: string) => {
  return text.replace(/```html\n([\s\S]+?)\n```/g, "");
};

export const getContentWithoutMentionAndTags = (content: string, mention: string, tags: string[]) => {
  let result = content;
  if (mention) {
    result = result.replace("@" + mention, "");
  }
  tags.forEach((tag) => {
    result = result.replace("#" + tag, "");
  });
  return result.trim();
};

export const isMobile = () => {
  return ("ontouchstart" in window || navigator.maxTouchPoints) && window.innerWidth <= 800;
};

export const getNodeId = (content: string): string | null => {
  const match = content.match(/#id_(.+?)(\s|$)/);
  if (match) {
    return match[1];
  }
  return null;
};

export const getSubdomain = () => {
  const hostname = window.location.hostname;
  if (hostname.split(".").length > 2) {
    const subdomain = hostname.split(".")[0];
    if (subdomain === "test") {
      return null;
    }
    return subdomain;
  }

  return null;
};
