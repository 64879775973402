import Api from "./api";
import { FileInfo } from "./models";

export class FileHelpers {
  static queuedFilesKey = "queuedFiles";

  static isLocalFile = (fileId: string) => fileId && !fileId.includes("-");

  static getFileInfo = async (fileId: string) => {
    if (FileHelpers.isLocalFile(fileId)) {
      return FileHelpers.getLocalFileInfo(fileId) || undefined;
    } else {
      return await Api.getFileInfo(fileId);
    }
  };

  static uploadSavedFile = async (fileId: string) => {
    const savedFiles = FileHelpers.getSavedFiles();
    const file = savedFiles.find((f) => f.id === fileId);
    if (file) {
      const formData = FileHelpers.deserializeFormData(file.file);
      const newFileId = await Api.uploadFile(file.filename, formData);
      savedFiles.splice(savedFiles.indexOf(file), 1);
      localStorage.setItem(FileHelpers.queuedFilesKey, JSON.stringify(savedFiles));
      return newFileId;
    }

    return undefined;
  };

  static uploadFile = async (filename: string, formData: FormData) => {
    if (!navigator.onLine) {
      var files = FileHelpers.getSavedFiles();
      const id = Date.now().toString();
      const file = await FileHelpers.serializeFormData(formData);
      const url = URL.createObjectURL(formData.get("file") as File);
      files.push({ filename, file, id, url });
      localStorage.setItem(FileHelpers.queuedFilesKey, JSON.stringify(files));
      return id;
    } else {
      return Api.uploadFile(filename, formData);
    }
  };

  static getLocalFileInfo = (localFileId: string): FileInfo | null => {
    const savedFiles = FileHelpers.getSavedFiles();
    const file = savedFiles.find((f) => f.id === localFileId);
    if (file) {
      const formData = FileHelpers.deserializeFormData(file.file);
      const fi = formData.get("file") as File;
      return {
        filename: file.filename,
        contentType: fi.type,
        fileUrl: file.url,
      } as FileInfo;
    }

    return null;
  };

  static getIconAndFileExtension = (contentType: string, filename?: string) => {
    if (filename && filename.toLowerCase().endsWith(".vcs")) {
      return {
        icon: "ContactCard",
        ext: "vcs",
      };
    }

    switch (contentType) {
      case "text/x-yaml":
        return {
          icon: "FileYML",
          ext: "yaml",
        };
      case "application/json":
        return {
          icon: "FileCode",
          ext: "json",
        };
      case "text/x-vcard":
        return {
          icon: "ContactCard",
          ext: "vcf",
        };
      case "text/calendar":
        return {
          icon: "Calendar",
          ext: "ics",
        };
      case "text/csv":
        return {
          icon: "Table",
          ext: "csv",
        };
      case "image/jpeg":
        return {
          icon: "FileImage",
          ext: "jpg",
        };
      case "image/png":
        return {
          icon: "FileImage",
          ext: "png",
        };
      case "image/gif":
        return {
          icon: "FileImage",
          ext: "gif",
        };
      case "image/svg+xml":
        return {
          icon: "FileImage",
          ext: "svg",
        };
      case "application/pdf":
        return {
          icon: "PDF",
          ext: "pdf",
        };
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return {
          icon: "WordDocument",
          ext: "docx",
        };
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return {
          icon: "ExcelDocument",
          ext: "xlsx",
        };
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        return {
          icon: "PowerPointDocument",
          ext: "pptx",
        };
      case "application/zip":
      case "application/x-zip-compressed":
        return {
          icon: "ZipFolder",
          ext: "zip",
        };
      case "application/msword":
        return {
          icon: "WordDocument",
          ext: "doc",
        };
      case "application/vnd.ms-excel":
        return {
          icon: "ExcelDocument",
          ext: "xls",
        };
      case "application/vnd.ms-powerpoint":
        return {
          icon: "PowerPointDocument",
          ext: "ppt",
        };
      case "text/plain":
        return {
          icon: "TextDocument",
          ext: "txt",
        };
      case "video/mp4":
        return {
          icon: "Video",
          ext: "mp4",
        };
      case "video/quicktime":
        return {
          icon: "Video",
          ext: "mov",
        };
      case "video/x-msvideo":
      case "video/avi":
        return {
          icon: "Video",
          ext: "avi",
        };
      case "video/x-ms-wmv":
        return {
          icon: "Video",
          ext: "wmv",
        };
      case "video/x-ms-wmx":
        return {
          icon: "Video",
          ext: "wmx",
        };
      case "video/webm":
        return {
          icon: "Video",
          ext: "webm",
        };
      case "audio/mpeg":
        return {
          icon: "Audio",
          ext: "mp3",
        };
      case "audio/x-m4a":
        return {
          icon: "Audio",
          ext: "m4a",
        };
      case "audio/x-ms-wma":
        return {
          icon: "Audio",
          ext: "wma",
        };
      case "audio/x-wav":
      case "audio/wav":
        return {
          icon: "Audio",
          ext: "wav",
        };
      case "audio/ogg":
        return {
          icon: "Audio",
          ext: "ogg",
        };
      case "text/markdown":
      case "application/x-mobipocket-ebook":
        return {
          icon: "OpenFile",
          ext: "md",
        };
      case "application/x-gzip":
        return {
          icon: "ZipFolder",
          ext: "gz",
        };
      case "application/x-sh":
        return {
          icon: "TextDocument",
          ext: "sh",
        };
      case "application/x-rar-compressed":
        return {
          icon: "ZipFolder",
          ext: "rar",
        };
      case "application/x-7z-compressed":
        return {
          icon: "ZipFolder",
          ext: "7z",
        };
      case "application/x-tar":
        return {
          icon: "ZipFolder",
          ext: "tar",
        };
      case "application/x-bzip":
        return {
          icon: "ZipFolder",
          ext: "bz",
        };
      case "application/x-bzip2":
        return {
          icon: "ZipFolder",
          ext: "bz2",
        };
      case "application/x-font-ttf":
        return {
          icon: "Font",
          ext: "ttf",
        };
      case "application/x-font-woff":
        return {
          icon: "Font",
          ext: "woff",
        };
      case "application/x-font-woff2":
        return {
          icon: "Font",
          ext: "woff2",
        };
      case "application/x-font-otf":
        return {
          icon: "Font",
          ext: "otf",
        };
      case "application/x-font-eot":
        return {
          icon: "Font",
          ext: "eot",
        };
      case "application/x-font-snf":
        return {
          icon: "Font",
          ext: "snf",
        };
      case "application/x-font-pcf":
        return {
          icon: "Font",
          ext: "pcf",
        };
      // css, html, cs, cpp, h etc...
      case "text/css":
        return {
          icon: "TextDocument",
          ext: "css",
        };
      case "text/html":
        return {
          icon: "TextDocument",
          ext: "html",
        };
      case "text/x-c":
        return {
          icon: "TextDocument",
          ext: "c",
        };
      case "text/x-c++":
        return {
          icon: "TextDocument",
          ext: "cpp",
        };
      case "text/x-h":
        return {
          icon: "TextDocument",
          ext: "h",
        };
      case "text/x-java":
        return {
          icon: "TextDocument",
          ext: "java",
        };
      // javascript
      case "text/javascript":
      case "application/javascript":
      case "application/x-javascript":
        return {
          icon: "TextDocument",
          ext: "js",
        };
      case "text/x-json":
        return {
          icon: "TextDocument",
          ext: "json",
        };
      // typescript
      case "text/typescript":
      case "application/typescript":
        return {
          icon: "TextDocument",
          ext: "ts",
        };
      case "text/x-sass":
        return {
          icon: "TextDocument",
          ext: "sass",
        };
      case "text/x-scss":
        return {
          icon: "TextDocument",
          ext: "scss",
        };
      case "text/x-less":
        return {
          icon: "TextDocument",
          ext: "less",
        };
      case "text/x-svelte":
        return {
          icon: "TextDocument",
          ext: "svelte",
        };
      case "text/jsx":
        return {
          icon: "TextDocument",
          ext: "jsx",
        };
      case "text/tsx":
        return {
          icon: "TextDocument",
          ext: "tsx",
        };
      case "text/x-python":
      case "text/python":
      case "application/python":
        return {
          icon: "TextDocument",
          ext: "py",
        };
      case "text/x-ruby":
        return {
          icon: "TextDocument",
          ext: "rb",
        };
      case "text/x-sql":
        return {
          icon: "TextDocument",
          ext: "sql",
        };
      case "text/x-perl":
        return {
          icon: "TextDocument",
          ext: "pl",
        };
      case "text/x-php":
        return {
          icon: "TextDocument",
          ext: "php",
        };
      case "text/x-csharp":
        return {
          icon: "TextDocument",
          ext: "cs",
        };
      case "application/vnd.ms-excel.sheet.macroEnabled.12":
        return {
          icon: "ExcelDocument",
          ext: "xlsm",
        };
      case "application/epub+zip":
        return {
          icon: "OpenFile",
          ext: "epub",
        };
      case "application/vnd.ms-outlook":
        return {
          icon: "Mail",
          ext: "msg",
        };
      case "message/rfc822":
        return {
          icon: "Mail",
          ext: "eml",
        };
      default:
        return {
          icon: "OpenFile",
          ext: "bin",
        };
    }
  };

  static getFileUrl = (fileId: string, ext: string) => {
    if (FileHelpers.isLocalFile(fileId)) {
      const file = FileHelpers.getLocalFileInfo(fileId);
      return file ? file.fileUrl : undefined;
    } else {
      return Api.downloadFileUrl(fileId, ext);
    }
  };

  private static getSavedFiles = () => {
    return JSON.parse(localStorage.getItem(FileHelpers.queuedFilesKey) || "[]") as {
      filename: string;
      file: string;
      id: string;
      url?: string;
    }[];
  };

  private static serializeFormData = (formData: FormData) =>
    new Promise<string>((resolve) => {
      const file = formData.get("file") as File;
      const { name, lastModified, size, type } = file;
      let read = new FileReader();
      read.readAsArrayBuffer(file);
      read.onloadend = () => {
        const data = Array.from(new Uint8Array(read.result as ArrayBuffer));
        const fileInfo = { name, lastModified, size, type, data };
        resolve(JSON.stringify(fileInfo));
      };
    });

  private static deserializeFormData = (fileInfo: string) => {
    // eslint-disable-next-line
    const { name, lastModified, _size, type, data } = JSON.parse(fileInfo);
    const file = new File([new Uint8Array(data)], name, { lastModified, type });
    const formData = new FormData();
    formData.append("file", file);

    // Create url for preview
    // TODO: use this for image preview when offline: const url = URL.createObjectURL(file);

    return formData;
  };
}
